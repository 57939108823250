export enum HIVE_EVENT_TYPES_ENUM {
  HiveMovement = "HiveMovement",
  HiveInspection = "HiveInspection",
  HiveTreatment = "HiveTreatment",
  HiveMeasurement = "HiveMeasurement",
  HiveEquipment = "HiveEquipment",
  ColonyStrengthAssessment = "ColonyStrengthAssessment",
  WeightMeasurement = "WeightMeasurement",
  BroodFramesCount = "BroodFramesCount",
  HoneyFramesCount = "HoneyFramesCount",
}

export enum HIVE_MOVEMENT_EVENTS_ENUM {
  TransferToOtherApiary = "TransferToOtherApiary",
  TransferWithinApiary = "TransferWithinApiary",
}

export enum HIVE_EQUIPMENT_EVENTS_ENUM {
  ADD_FRAMES = "AddFrames",
  REMOVE_FRAMES = "RemoveFrames",
  ADD_SUPER = "AddSuper",
  REMOVE_SUPER = "RemoveSuper",
  ADD_BROOD_BOX = "AddBroodBox",
  REMOVE_BROOD_BOX = "RemoveBroodBox",
}

export enum HIVE_MEASUREMENT_TYPES_ENUM {
  COLONY_STRENGTH = "Colony Strength",
  HONEY_FRAMES = "Honey Frames",
  BROOD_FRAMES = "Brood Frames",
  WEIGHT = "Weight",
}

export enum HIVE_MEASUREMENT_UNITS_ENUM {
  KILOGRAMS = "kg",
  GRAMS = "g",
  PERCENT = "%",
  COUNT = "count",
}

export enum HIVE_EQUIPMENT_TYPES_ENUM {
  FRAME = "Frame",
  SUPER = "Super",
  BROOD_BOX = "Brood Box",
}

export enum EQUIPMENT_ACTINS {
  ADD = "Add",
  REMOVE = "Remove",
}
