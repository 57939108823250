import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import tractoareLogo from "@/assets/partners-assets/tractoare-ro/tractoare-ro-logo.png"
import PageContentWrapperLarge from "@/components/page-content-wrappers/page-content-wrapper-large.tsx"
import MobileTopBar from "@/components/top-bars/mobile-top-bar/mobile-top-bar.tsx"
import { PARTNER_TRACTOARE_RO } from "@/utils/constants/routes.ts"

export const AllPartnersView = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <>
      <MobileTopBar title={t("partners")} />
      <PageContentWrapperLarge>
        <div
          className={
            "widget-border box-border flex flex-col gap-5 bg-white p-4"
          }
        >
          <img
            className={"h-auto w-3/4"}
            src={tractoareLogo}
            alt="tractoare-ro.ro"
          />
          <p>
            Veți găsi în magazinul nostru cele mai noi tractoare, echipamente,
            utilaje, accesorii și consumabile pentru agricultură, industrie,
            construcții civile și servicii urbanistice. Cu o experiență de peste
            20 de ani în domeniu, suntem pregătiți să vă oferim cele mai bune
            echipamente și servicii, adaptate nevoilor dumneavoastră.
          </p>
          <button
            onClick={() => navigate(PARTNER_TRACTOARE_RO)}
            className="inline-flex w-full items-center justify-center gap-1 rounded-full border-[1px] border-brown-500 bg-white px-6 py-3 text-[16px] font-semibold text-brown-500"
          >
            {t("viewOffer")}
          </button>
        </div>
      </PageContentWrapperLarge>
    </>
  )
}
