import omitBy from "lodash/omitBy"
import { apiSlice, TagType } from "@/features/api/apiSlice.ts"
import { isEmptyValue } from "@/features/events/common/utils/utils.ts"
import { type HiveEvent } from "@/features/events/hive-events/types/hive-event.ts"

const HIVE_EVENTS_API_BASE_URL = "/hive-events/"

interface GetHiveEventsParams {
  hive_id?: string
  apiary_id?: string
  date_from?: string
  date_to?: string
  event_type?: string
}

export type HiveEventInput = Omit<
  HiveEvent,
  "id" | "user" | "created_at" | "updated_at"
>

export const hiveEventsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllHiveEvents: builder.query<HiveEvent[], Partial<GetHiveEventsParams>>({
      query: (params) => ({
        url: HIVE_EVENTS_API_BASE_URL,
        params: {
          hive_id: params.hive_id,
          apiary_id: params.apiary_id,
          date_from: params.date_from,
          date_to: params.date_to,
          event_type: params.event_type,
        },
      }),
      providesTags: [{ type: TagType.HiveEvents, id: "LIST" }],
    }),
    getHiveEventById: builder.query<HiveEvent, string>({
      query: (id) => `${HIVE_EVENTS_API_BASE_URL}${id}/`,
      providesTags: (_Result, _error, id) => [{ type: TagType.HiveEvents, id }],
    }),
    createHiveEvent: builder.mutation<HiveEvent, HiveEventInput>({
      query: (body) => {
        const { event_data, ...rest } = body
        const filteredEventSubData = omitBy(event_data, isEmptyValue)

        const modifiedBody = {
          ...rest,
          event_sub_data: Object.keys(filteredEventSubData).length
            ? filteredEventSubData
            : undefined,
        }

        return {
          url: HIVE_EVENTS_API_BASE_URL,
          method: "POST",
          body: modifiedBody,
        }
      },
      invalidatesTags: (_result, _error, body) => [
        { type: TagType.HiveEvents, id: "LIST" },
        { type: TagType.Hives, id: body.hive },
        { type: TagType.Hives, id: "LIST" },
      ],
    }),
    updateHiveEvent: builder.mutation<
      HiveEvent,
      HiveEventInput & { id: string }
    >({
      query: (patch) => {
        const { event_data, ...rest } = patch
        const { event, ...event_sub_data } = event_data || {}
        const modifiedPatch = {
          ...rest,
          event_sub_data: event_sub_data,
        }
        return {
          url: `${HIVE_EVENTS_API_BASE_URL}${patch.id}/`,
          method: "PATCH",
          body: modifiedPatch,
        }
      },
      invalidatesTags: (_result, _error, body) => [
        { type: TagType.HiveEvents, id: body.id },
        { type: TagType.HiveEvents, id: "LIST" },
        { type: TagType.Hives, id: body.hive },
      ],
    }),
    deleteHiveEvent: builder.mutation<void, HiveEvent>({
      query: (hiveEvent) => ({
        url: `${HIVE_EVENTS_API_BASE_URL}${hiveEvent.id}/`,
        method: "DELETE",
      }),
      invalidatesTags: (_result, _error, hiveEvent) => [
        { type: TagType.HiveEvents, id: hiveEvent.id },
        { type: TagType.HiveEvents, id: "LIST" },
        { type: TagType.Hives, id: hiveEvent.hive },
      ],
    }),
  }),
})

export const {
  useGetAllHiveEventsQuery,
  useCreateHiveEventMutation,
  useGetHiveEventByIdQuery,
  useDeleteHiveEventMutation,
  useUpdateHiveEventMutation,
} = hiveEventsApi
