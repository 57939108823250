import React from "react"
import { useTranslation } from "react-i18next"
import LocationIcon from "@/assets/icons/farm/location-icon.tsx"
import DrawerContentWrapper from "@/components/drawer-content-wrapper/drawer-content-wrapper.tsx"
import GenericTypeDrawerHeader from "@/components/generic-type-drawer/generic-type-drawer-header/generic-type-drawer-header.tsx"
import NoResultsFound from "@/components/not-found/no-results-found.tsx"
import { SelectionRow } from "@/components/selection-row/selection-row.tsx"
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerTrigger,
} from "@/components/shadcn/drawer.tsx"
import OverflowMenuItem from "@/components/task-card/overflow-menu-item.tsx"
import RequiredIndicator from "@/features/farm/components/required-indicator/required-indicator.tsx"
import { cn } from "@/utils/styles.ts"
import { type Option } from "@/utils/types/misc-types.ts"

interface Props {
  value: string | undefined
  onChangeValue: (value: string) => void
  options: Option[]
  placeholder: string
}

export const LocationDrawer: React.FC<Props> = ({
  value,
  onChangeValue,
  options,
  placeholder,
}) => {
  const { t } = useTranslation()
  const isSelected = (location: string): boolean => {
    return location === value
  }
  const [inputValue, setInputValue] = React.useState("")

  const baseButtonClassName =
    "flex h-[50px] w-full items-center justify-center rounded-[8px] bg-brown-500 text-[14px] font-semibold text-white"
  const buttonClassName = inputValue
    ? baseButtonClassName
    : cn(baseButtonClassName, "bg-gray-300")

  const handleAddLocation = () => {
    if (inputValue) {
      onChangeValue(inputValue)
      setInputValue("")
    }
  }

  return (
    <OverflowMenuItem>
      <Drawer>
        <DrawerTrigger>
          <div className={"grid grid-cols-[30px_auto] items-center gap-2"}>
            <div
              className={
                "grid h-[30px] w-[30px] place-content-center rounded-[4px]"
              }
            >
              <LocationIcon />
            </div>
            <div className={"flex justify-between"}>
              <div className={"flex flex-col items-start"}>
                <div className={"text-[14px]"}>
                  {value ? t(value) : t(placeholder)}
                </div>
              </div>
              {!value && <RequiredIndicator className={"absolute top-3.5"} />}
            </div>
          </div>
        </DrawerTrigger>
        <DrawerContent className="h-3/4 overflow-hidden">
          <GenericTypeDrawerHeader
            title={t(placeholder)}
            onSaveClick={() => {}}
          />
          <div className={"ml-2 mt-4 text-[14px] text-neutral-400"}>
            {t("currentLocation")}
          </div>
          <div className={"h-[40px]"}>
            {value ? (
              <SelectionRow
                option={{
                  value: value,
                  label: value,
                }}
                isSelected={true}
                onSelectionChange={() => {}}
              />
            ) : (
              <div className={"flex h-full w-full items-center justify-center"}>
                {t("noCurrentLocation")}
              </div>
            )}
          </div>
          <div className={"ml-2 mt-2 text-[14px] text-neutral-400"}>
            {t("addNewLocation")}
          </div>
          <div className={"flex flex-col gap-2 p-2"}>
            <input
              type="text"
              placeholder={""}
              className={
                "h-10 w-full rounded-[8px] border border-gray-300 px-3 text-[16px]"
              }
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
            />
            <DrawerClose>
              <button
                disabled={!inputValue}
                onClick={handleAddLocation}
                className={buttonClassName}
              >
                {t("addLocation")}
              </button>
            </DrawerClose>
          </div>
          <div className={"ml-2 mt-4 text-[14px] text-neutral-400"}>
            {t("previousLocations")}
          </div>
          <DrawerContentWrapper className="pb-5">
            {options &&
              options.map((option) => (
                <SelectionRow
                  key={option.value}
                  selectionMode={"single"}
                  option={option}
                  isSelected={isSelected(option.value)}
                  onSelectionChange={() => onChangeValue(option.value)}
                />
              ))}
            {options.length === 0 && (
              <NoResultsFound
                message={t("noPreviousLocations")}
                image={"location"}
                className={"h-full"}
                imageClassName={"w-20 h-20 opacity-50"}
              />
            )}
          </DrawerContentWrapper>
        </DrawerContent>
      </Drawer>
    </OverflowMenuItem>
  )
}
