import React, { type ChangeEvent, type ReactNode } from "react"
import styles from "./text-card-wrapper-with-icon.module.css"
import OverflowMenuItem from "@/components/task-card/overflow-menu-item.tsx"

interface TextCardProps {
  icon: ReactNode
  name: string
  isReadOnly: boolean
  value: any
  maxLength?: number
  onChange: (event: ChangeEvent<HTMLTextAreaElement>) => void
}

const TextAreaFieldWrapperWithIcon: React.FC<TextCardProps> = ({
  icon,
  name,
  isReadOnly,
  value = "",
  onChange,
  maxLength = 200,
}) => {
  return (
    <OverflowMenuItem>
      <div className={styles.icon}>{icon}</div>
      <textarea
        name={name}
        className={`${styles.textInputArea} ${isReadOnly ? styles.inputDisabled : ""}`}
        placeholder={name}
        value={value}
        maxLength={maxLength}
        rows={6}
        onChange={(event: ChangeEvent<HTMLTextAreaElement>) => onChange(event)}
        readOnly={isReadOnly}
      />
    </OverflowMenuItem>
  )
}

export default TextAreaFieldWrapperWithIcon
