import { useGetAllHiveEventTypesQuery } from "@/features/events/hive-events/api/hive-event-types-api.ts"

const useCheckApiaryEventType = () => {
  const { data: eventTypes } = useGetAllHiveEventTypesQuery()

  return (eventId: string | undefined, type: string) => {
    if (!eventId || !eventTypes) return false

    const eventType = eventTypes.find((event) => event.id === eventId)
    if (!eventType) return false

    const isType = eventType.name === type

    const isParentType =
      eventType.parent &&
      eventTypes.find((event) => event.id === eventType.parent)?.name === type

    return Boolean(isType || isParentType)
  }
}

export default useCheckApiaryEventType
