import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import ArrowDown from "@/assets/icons/misc/arrow-down.tsx"
import PlaceholderIcon from "@/assets/icons/misc/placeholder-icon.tsx"
import RoundedEditIcon from "@/assets/icons/misc/rounded-edit-icon.tsx"
import { DrawerClose } from "@/components/shadcn/drawer.tsx"
import { type AnimalEventType } from "@/features/events/animal-events/types/animal-events.ts"
import { getIconBackgroundColor } from "@/features/events/animal-events/utils/animal-events-utils.ts"

interface AnimalEventsTypeDropdownProps {
  selectedEvent: string | undefined
  onClick: (eventTypeId: string | undefined) => void
  isOpen: boolean
  onOpen: () => void
  eventTypes: [string, AnimalEventType[]]
  onViewChange: (entityId: string) => void
}

const EventsTypeDropdown: React.FC<AnimalEventsTypeDropdownProps> = ({
  selectedEvent,
  onClick,
  eventTypes,
  isOpen,
  onOpen,
  onViewChange,
}) => {
  const { t } = useTranslation()

  const isAnySelected = eventTypes[1].some((event) => {
    return event.id == selectedEvent
  })

  // This is used when we open the drawer,
  // if the dropdown contains the selected choice this will be opened by default,
  // This only happens if a prev selection is made
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (isAnySelected) {
      onOpen()
    }
  }, [])

  const selectEventType = (eventTypeId: string) => {
    if (eventTypeId === selectedEvent) {
      onClick(undefined)
    } else {
      onClick(eventTypeId)
    }
  }

  const handleEditClick = (eventType: AnimalEventType) => {
    eventType.farm && onViewChange(eventType.id)
  }

  const backGroundColor = getIconBackgroundColor(eventTypes[0])

  const getClassName = (event: AnimalEventType) => {
    if (event.id == selectedEvent) {
      return "flex h-[40px] items-center justify-between px-6 bg-brown-200"
    }
    return "flex h-[40px] items-center justify-between px-6"
  }

  return (
    <>
      <div
        className="-mx-4 flex items-center justify-between border-b border-[--light-grey] px-10 py-4"
        onClick={onOpen}
      >
        <div className="flex items-center justify-center gap-4">
          <p className="line-clamp-1 overflow-hidden text-ellipsis whitespace-pre-wrap break-words text-sm">
            {t(eventTypes[0])}
          </p>
          {isAnySelected && (
            <div className="h-4 w-4 rounded-full bg-[rgba(229,101,15,0.4)]" />
          )}
        </div>
        {isOpen ? <ArrowDown isDown={false} /> : <ArrowDown />}
      </div>
      {isOpen && (
        <div className={"flex flex-col"}>
          {eventTypes[1].map((event, index) => (
            <div
              key={index}
              className={getClassName(event)}
              onClick={() => selectEventType(event.id)}
            >
              <DrawerClose className={"flex items-center gap-[10px]"}>
                {event.picture ? (
                  <img
                    src={event.picture}
                    alt={"eventPicture"}
                    className={"h-[30px] w-[30px] rounded-[8px] p-1"}
                    style={{ backgroundColor: backGroundColor }}
                  />
                ) : (
                  <PlaceholderIcon />
                )}

                <div
                  className={
                    "line-clamp-1 overflow-hidden text-ellipsis whitespace-pre-wrap break-words text-sm"
                  }
                >
                  {t(event.name)}
                </div>
              </DrawerClose>
              {event.farm && (
                <RoundedEditIcon
                  className={"h-[30px] w-[30px]"}
                  onClick={() => handleEditClick(event)}
                />
              )}
            </div>
          ))}
          <div className="border-b border-[--light-grey]"></div>
        </div>
      )}
    </>
  )
}

export default EventsTypeDropdown
