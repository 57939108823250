import useGetEventsLocation from "@/features/events/common/hooks/use-get-events-location.ts"
import { useGetAllHiveEventsQuery } from "@/features/events/hive-events/api/hive-events-api.ts"

const useGetHiveEventsLocation = () => {
  const { data } = useGetAllHiveEventsQuery({})
  const { fromPosition, toPosition } = useGetEventsLocation({
    events: data,
  })

  const fromLocationOptionArray = Array.from(fromPosition).map((location) => {
    return {
      value: location,
      label: location,
    }
  })

  const toLocationOptionArray = Array.from(toPosition).map((location) => {
    return {
      value: location,
      label: location,
    }
  })

  return [...fromLocationOptionArray, ...toLocationOptionArray]
}

export default useGetHiveEventsLocation
