import type GeneralSvgProps from "@/utils/types/sgv/general-svg-props.ts"

const WeightScaleIcon: React.FC<GeneralSvgProps> = (props) => {
  return (
    <svg
      {...props}
      viewBox="-4.35 -4.35 37.70 37.70"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
    >
      <g id="SVGRepo_bgCarrier" stroke-width="0">
        <rect
          x="-4.35"
          y="-4.35"
          width="37.70"
          height="37.70"
          rx="7.54"
          fill="#7ed0ec"
          strokeWidth="0"
        ></rect>
      </g>
      <g
        id="SVGRepo_tracerCarrier"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        <g
          id="Page-1"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
        >
          <g
            id="Icon-Set"
            transform="translate(-518.000000, -205.000000)"
            fill="currentColor"
          >
            <path
              d="M546,218.984 L532,218.984 C531.447,218.984 531,219.433 531,219.985 C531,220.538 531.447,220.986 532,220.986 L546,220.986 C546.553,220.986 547,220.538 547,219.985 C547,219.433 546.553,218.984 546,218.984 L546,218.984 Z M546,212.978 L538,213 C537.447,213 537,213.426 537,213.979 C537,214.532 537.447,215 538,215 L546,214.979 C546.553,214.979 547,214.532 547,213.979 C547,213.426 546.553,212.978 546,212.978 L546,212.978 Z M546,224.991 L532,224.991 C531.447,224.991 531,225.439 531,225.992 C531,226.545 531.447,226.993 532,226.993 L546,226.993 C546.553,226.993 547,226.545 547,225.992 C547,225.439 546.553,224.991 546,224.991 L546,224.991 Z M534,209 L546,209 C546.553,209 547,208.525 547,207.973 C547,207.419 546.553,206.971 546,206.971 L534,206.971 C533.447,206.971 533,207.419 533,207.973 C533,208.525 533.447,209 534,209 L534,209 Z M533.687,214.697 C534.079,214.304 534.079,213.666 533.687,213.271 L526.745,205.283 C526.535,205.073 526.258,204.983 525.984,204.998 C525.711,204.983 525.434,205.073 525.224,205.283 L518.282,213.271 C517.89,213.666 517.89,214.304 518.282,214.697 C518.674,215.091 519.31,215.091 519.701,214.697 L525,208.601 L525,233 L527,233 L527,208.636 L532.268,214.697 C532.659,215.091 533.295,215.091 533.687,214.697 L533.687,214.697 Z M546,231 L532,231 C531.447,231 531,231.446 531,231.999 C531,232.552 531.447,233 532,233 L546,233 C546.553,233 547,232.552 547,231.999 C547,231.446 546.553,231 546,231 L546,231 Z"
              id="Fill-194"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default WeightScaleIcon
