import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { LoadingAnimation } from "@/components/loading-animation/loading-animation.tsx"
import PageContentWrapperLarge from "@/components/page-content-wrappers/page-content-wrapper-large.tsx"
import EditorTopBar from "@/components/top-bars/editor-top-bar/editor-top-bar.tsx"
import { useCreateHiveEventMutation } from "@/features/events/hive-events/api/hive-events-api.ts"
import { HiveEventEditor } from "@/features/events/hive-events/components/hive-event-editor/hive-event-editor.tsx"
import { useGetHiveEventDataTypeObject } from "@/features/events/hive-events/hooks/use-get-hive-event-data-type-object.ts"
import { useInitializeHiveEventDraft } from "@/features/events/hive-events/hooks/use-initialize-hive-event-draft.ts"
import { validateHiveEvent } from "@/features/events/hive-events/types/hive-event-schema.tsx"
import useModal from "@/features/modals/hooks/use-modal.ts"
import { MODALS } from "@/features/modals/types/modals.tsx"
import useNavigationFallback from "@/hooks/use-navigation-fallback.ts"
import { useAppDispatch } from "@/redux/hooks.ts"
import { resetHiveEventDraft } from "@/redux/slices/hive-events-draft-slice.ts"

export const NewHiveEventView = () => {
  const { t } = useTranslation()
  const { openModal } = useModal()
  const dispatch = useAppDispatch()
  const [createHiveEvent] = useCreateHiveEventMutation()
  const { hiveEventDraft, hasChanges } = useInitializeHiveEventDraft()
  const { handleBackNavigation } = useNavigationFallback()
  const hiveEventDataTypeObject = useGetHiveEventDataTypeObject(
    hiveEventDraft.type,
  )

  const onBackClick = () => {
    if (hasChanges) {
      openModal(MODALS.BASE_MODAL, {
        title: t("unsavedChanges"),
        content: t("youWillLoseChanges"),
        onActionClick: () => {
          dispatch(resetHiveEventDraft({}))
          handleBackNavigation()
        },
      })
    } else {
      handleBackNavigation()
    }
  }

  const handleSave = async () => {
    if (!(await validateHiveEvent(hiveEventDraft, hiveEventDataTypeObject))) {
      return
    }

    const promise = createHiveEvent(hiveEventDraft).unwrap()
    await toast
      .promise(promise, {
        pending: t("creatingHiveEvent"),
        success: t("hiveEventCreatedSuccessfully"),
        error: t("errorCreatingHiveEvent"),
      })
      .then(() => {
        handleBackNavigation()
        dispatch(resetHiveEventDraft({}))
      })
      .catch((err) => {
        const errorDetail = err.data?.detail
        const errorMessage = errorDetail
          ? errorDetail.split(":").slice(1).join(":").trim()
          : "errorCreatingHiveEvent"

        toast.error(t(errorMessage))
      })
  }

  return (
    <>
      <EditorTopBar
        text={t("addEvent")}
        isModified={hasChanges}
        onBackClick={onBackClick}
        onSave={handleSave}
      />
      <PageContentWrapperLarge>
        {!hiveEventDraft ? (
          <LoadingAnimation />
        ) : (
          <HiveEventEditor draftHiveEvent={hiveEventDraft} />
        )}
      </PageContentWrapperLarge>
    </>
  )
}
