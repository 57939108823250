import { skipToken } from "@reduxjs/toolkit/query"
import { useLayoutEffect } from "react"
import { useGetQueenByIdQuery } from "@/features/bees/api/queens-api.ts"
import { useAppDispatch, useAppSelector } from "@/redux/hooks.ts"
import { setQueenDraft } from "@/redux/slices/queen-draft-slice.ts"

export const useGetQueenDraft = (queenId?: string) => {
  const dispatch = useAppDispatch()
  const { data, isLoading } = useGetQueenByIdQuery(queenId || skipToken)

  const draftQueen = useAppSelector(
    (state) => state.queenDraftSlice.drafts[queenId || ""],
  )

  useLayoutEffect(() => {
    if (data && queenId && !draftQueen) {
      dispatch(
        setQueenDraft({
          id: queenId,
          draft: data,
        }),
      )
    }
  }, [data, dispatch, draftQueen, queenId])

  return { draftQueen, data, isLoading }
}
