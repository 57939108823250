import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import GenericButtonTrigger from "@/components/drawer-triggers/generic-button-trigger.tsx"
import GenericTypeSelectTrigger from "@/components/drawer-triggers/generic-type-select-trigger.tsx"
import AddItemGenericDrawerSelectionRow from "@/components/generic-drawer-selection-row/add-item-generic-drawer-selection-row.tsx"
import {
  Drawer,
  DrawerContent,
  DrawerTrigger,
} from "@/components/shadcn/drawer.tsx"
import AddEventTypeForm from "@/features/events/animal-events/components/add-event-type-form/add-event-type-form.tsx"
import EventTypeDrawerContent from "@/features/events/animal-events/components/event-type-drawer/event-type-drawer-content.tsx"
import { type AnimalEventType } from "@/features/events/animal-events/types/animal-events.ts"
import {
  findParentTypeOfEvent,
  getIconBackgroundColor,
} from "@/features/events/animal-events/utils/animal-events-utils.ts"

/**
 * DrawerTriggerTypes
 *
 * Choose Pill if you want to get a pill-like trigger, usually used for filtering options.
 * Choose Button if you want to get a button-like trigger, usually used for associating an event type to an entity.
 */
export enum DrawerTriggerTypes {
  Button = "Button",
  Pill = "Pill",
}

interface GenericTypeDrawerProps {
  selectedValue: string | undefined
  fallbackText: string
  addEventEnabled?: boolean
  onEventSelect: (eventType: string | undefined) => void
  eventTypesMap: Map<string, AnimalEventType[]>
  triggerType?: DrawerTriggerTypes
}

interface ReducedEventTypes {
  [key: string]: AnimalEventType
}

const EventsTypeDrawer: React.FC<GenericTypeDrawerProps> = ({
  selectedValue,
  onEventSelect,
  eventTypesMap,
  fallbackText,
  addEventEnabled = true,
  triggerType = DrawerTriggerTypes.Pill,
}) => {
  const eventTypesValues = [...eventTypesMap.values()]
  const flatEventTypesValues = eventTypesValues.flat(1)
  const { t } = useTranslation()
  const [isAddEventTypeViewOpen, setIsAddEventTypeViewOpen] = useState<{
    isOpen: boolean
    eventTypeId: string | null
  }>({
    isOpen: false,
    eventTypeId: null,
  })

  const reducedEventTypesValues =
    flatEventTypesValues.reduce<ReducedEventTypes>(
      (acc, curr) => ({
        ...acc,
        [curr.id]: curr,
      }),
      {},
    )

  const currentEventType = selectedValue
    ? reducedEventTypesValues[selectedValue]
    : undefined

  const selectedValueParent =
    selectedValue && findParentTypeOfEvent(eventTypesMap, selectedValue)

  const imageBackGroundColor = selectedValueParent
    ? getIconBackgroundColor(selectedValueParent)
    : undefined

  const displayText = (currentValue: AnimalEventType | undefined) => {
    return currentValue ? t(currentValue.name) : t(fallbackText)
  }

  const handleEventTypeSelect = (eventType: string | undefined) => {
    onEventSelect(eventType)
  }

  const handleBackClick = () => {
    setIsAddEventTypeViewOpen({
      isOpen: false,
      eventTypeId: null,
    })
  }

  const handleViewChange = (entityId: string | null) => {
    setIsAddEventTypeViewOpen({
      isOpen: true,
      eventTypeId: entityId,
    })
  }

  return (
    <Drawer>
      <DrawerTrigger asChild>
        {triggerType === DrawerTriggerTypes.Pill ? (
          <GenericTypeSelectTrigger
            currentValue={currentEventType}
            fallbackText={fallbackText}
            style={{
              backgroundColor: imageBackGroundColor,
              padding: "5px",
            }}
          />
        ) : (
          <GenericButtonTrigger
            currentValue={currentEventType?.name}
            displayText={() => displayText(currentEventType)}
          />
        )}
      </DrawerTrigger>
      <DrawerContent
        style={{
          height: "85%",
          width: "100%",
        }}
      >
        {isAddEventTypeViewOpen.isOpen ? (
          <AddEventTypeForm
            onBackClick={handleBackClick}
            eventId={isAddEventTypeViewOpen.eventTypeId}
          />
        ) : (
          <>
            <EventTypeDrawerContent
              selectedEvent={selectedValue}
              onEventSelect={handleEventTypeSelect}
              eventTypesMap={eventTypesMap}
              onViewChange={handleViewChange}
            />
            {addEventEnabled && (
              <AddItemGenericDrawerSelectionRow
                text={t("addEventType")}
                onClick={() => handleViewChange(null)}
              />
            )}
          </>
        )}
      </DrawerContent>
    </Drawer>
  )
}

export default EventsTypeDrawer
