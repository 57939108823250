import { apiSlice, TagType } from "@/features/api/apiSlice.ts"
import { type Medication } from "@/features/events/common/types/common-events-types.ts"

const MEDICATION_TYPES_BASE_URL = "/medications/"

export const AnimalMedicationTypesApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllMedicationTypes: builder.query<Record<string, Medication>, void>({
      query: () => ({
        url: MEDICATION_TYPES_BASE_URL,
      }),
      transformResponse: (response: Medication[]) => {
        return response.reduce<Record<string, Medication>>((acc, eventType) => {
          return { ...acc, [eventType.id]: eventType }
        }, {})
      },
      providesTags: () => [{ type: TagType.MedicationTypes, id: "LIST" }],
    }),
    createMedicationType: builder.mutation<Medication, Partial<Medication>>({
      query: (body) => ({
        url: MEDICATION_TYPES_BASE_URL,
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: TagType.MedicationTypes, id: "LIST" }],
    }),
    updateMedicationType: builder.mutation<Medication, Partial<Medication>>({
      query: (body) => ({
        url: `${MEDICATION_TYPES_BASE_URL}${body.id}/`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: [{ type: TagType.MedicationTypes, id: "LIST" }],
    }),
    deleteMedicationType: builder.mutation<void, string>({
      query: (id) => ({
        url: `${MEDICATION_TYPES_BASE_URL}${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: TagType.MedicationTypes, id: "LIST" }],
    }),
  }),
})

export const {
  useGetAllMedicationTypesQuery,
  useCreateMedicationTypeMutation,
  useDeleteMedicationTypeMutation,
  useUpdateMedicationTypeMutation,
} = AnimalMedicationTypesApi
