import { useGetAllAnimalEventTypesQuery } from "@/features/events/animal-events/api/event-types-api.ts"

const useGetEventTypeParents = () => {
  const { data: eventTypesData } = useGetAllAnimalEventTypesQuery()

  return (
    eventTypesData &&
    Object.values(eventTypesData).filter(
      (eventType) => eventType.parent === null,
    )
  )
}

export default useGetEventTypeParents
