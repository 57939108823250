export enum QueenOrigins {
  Purchased = "Purchased",
  OwnProduction = "Own Production",
}

export const queenOriginOptions = Object.entries(QueenOrigins).map(
  ([_, value]) => ({
    value: value,
    label: value,
  }),
)

export enum QueenColors {
  Blue = "Blue",
  White = "White",
  Yellow = "Yellow",
  Red = "Red",
  Green = "Green",
}

export interface Queen {
  id: string
  name: string
  year?: string
  hive: string
  apiary: string
  color?: QueenColors
  origin: QueenOrigins
  description: string
  picture: string | null
  notes: string[]
  tasks: string[]
  created_at: string
  updated_at: string
}

export type QueenInput = Omit<Queen, "id" | "created_at" | "updated_at">

export interface QueenResponse extends Queen {
  hive: string
  apiary: string
  tasks: string[]
  notes: string[]
}

export type QueenSummary = Pick<Queen, "id" | "name" | "year" | "color">
