import React, { type ChangeEvent } from "react"
import { useTranslation } from "react-i18next"
import { ApiariesIcon } from "@/assets/icons/bees/apiaries-icon.tsx"
import DetailsIcon from "@/assets/icons/bees/details-icon.tsx"
import { DsvsaIcon } from "@/assets/icons/bees/dsvsa-icon.tsx"
import { TownAddressIcon } from "@/assets/icons/bees/town-address-icon.tsx"
import { AttachImageFieldBase64 } from "@/components/attach-image-field/attach-image-field-base-64.tsx"
import InputFieldWrapperWithIcon from "@/components/text-card-wrapper-with-icon/input-field-wrapper-with-icon.tsx"
import TextAreaFieldWrapperWithIcon from "@/components/text-card-wrapper-with-icon/text-area-field-wrapper-with-icon.tsx"
import { type ApiaryInput } from "@/features/bees/types/apiaries.ts"

interface Props {
  apiary: ApiaryInput
  onApiaryChange: (apiary: ApiaryInput) => void
}

export const ApiaryEditor: React.FC<Props> = ({ apiary, onApiaryChange }) => {
  const { t } = useTranslation()

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.value
    onApiaryChange({ ...apiary, name })
  }

  const handleAddressChange = (event: ChangeEvent<HTMLInputElement>) => {
    const address = event.target.value
    onApiaryChange({ ...apiary, address })
  }

  const handleRegistrationCodeChange = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    const registration_code = event.target.value
    onApiaryChange({ ...apiary, registration_code })
  }

  const handleDescriptionChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const description = event.target.value
    onApiaryChange({ ...apiary, description })
  }

  const handlePictureChange = (picture: string | null) => {
    onApiaryChange({ ...apiary, picture })
  }

  return (
    <div className={"flex flex-col gap-[10px] pb-10"}>
      <InputFieldWrapperWithIcon
        icon={
          <div
            className={
              "grid h-[30px] w-[30px] place-content-center rounded-[4px] bg-amber-400 text-white"
            }
          >
            <ApiariesIcon />
          </div>
        }
        align={"row"}
        isDisabled={false}
        isRequired={!apiary.name}
        name={t("apiaryName")}
        onChange={handleNameChange}
        value={apiary.name}
      />
      <InputFieldWrapperWithIcon
        icon={
          <div
            className={
              "grid h-[30px] w-[30px] place-content-center rounded-[4px] bg-blue-300 text-transparent"
            }
          >
            <TownAddressIcon />
          </div>
        }
        align={"row"}
        isDisabled={false}
        isRequired={!apiary.address}
        name={t("apiaryAddress")}
        onChange={handleAddressChange}
        value={apiary.address}
        maxLength={200}
      />
      <InputFieldWrapperWithIcon
        icon={
          <div
            className={
              "grid h-[30px] w-[30px] place-content-center rounded-[4px] bg-[var(--light-purple)] text-transparent"
            }
          >
            <DsvsaIcon />
          </div>
        }
        align={"row"}
        isDisabled={false}
        name={t("dsvsa")}
        isRequired={!apiary.registration_code}
        onChange={handleRegistrationCodeChange}
        value={apiary.registration_code}
      />
      <TextAreaFieldWrapperWithIcon
        icon={
          <div
            className={
              "grid h-[30px] w-[30px] place-content-center rounded-[4px] bg-[var(--menu-financial-journal-color)] text-white"
            }
          >
            <DetailsIcon className={"scale-150"} />
          </div>
        }
        maxLength={500}
        name={t("details")}
        onChange={handleDescriptionChange}
        value={apiary.description}
        isReadOnly={false}
      />
      <AttachImageFieldBase64
        picture={apiary.picture}
        onChoosePicture={handlePictureChange}
      />
    </div>
  )
}
