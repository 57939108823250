import { useGetAllApiaryEventsQuery } from "@/features/events/apiary-events/api/apiary-events-api.ts"
import useGetEventsLocation from "@/features/events/common/hooks/use-get-events-location.ts"

const useGetApiaryEventsLocation = () => {
  const { data: allEvents } = useGetAllApiaryEventsQuery({})
  const { fromLocation, toLocation } = useGetEventsLocation({
    events: allEvents,
  })

  const fromLocationOptionArray = Array.from(fromLocation).map((location) => {
    return {
      value: location,
      label: location,
    }
  })

  const toLocationOptionArray = Array.from(toLocation).map((location) => {
    return {
      value: location,
      label: location,
    }
  })

  return [...fromLocationOptionArray, ...toLocationOptionArray]
}

export default useGetApiaryEventsLocation
