import { format } from "date-fns"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { useGetAllApiaryEventTypesQuery } from "@/features/events/apiary-events/api/apiary-event-types-api.ts"
import { type ApiaryEventType } from "@/features/events/apiary-events/types/apiary-event-type.ts"
import { type ApiaryEvent } from "@/features/events/apiary-events/types/apiary-event.ts"
import styles from "@/features/events/common/components/events-table/events-table.module.css"
import { type BaseEvent } from "@/features/events/common/types/common-events-types.ts"
import { APIARY_EVENTS_ROUTE } from "@/utils/constants/routes.ts"

interface ApiaryEventTableRowProps<T extends BaseEvent> {
  event: T
  eventTypes: ApiaryEventType[]
  routePath: string
}

export const ApiaryEventTableRow = <T extends BaseEvent>({
  event,
  eventTypes,
  routePath,
}: ApiaryEventTableRowProps<T>) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const eventTypeName =
    eventTypes?.find((type) => type.id === event.type)?.name ?? ""
  const navigateToEvent = () => {
    navigate(`${routePath}/${event.id}`)
  }
  return (
    <tr className={styles.tableRow} onClick={navigateToEvent}>
      {eventTypeName && (
        <>
          <td className={styles.tableCell}>{t(eventTypeName)}</td>
          <td className={styles.dateCell}>
            {format(new Date(event.date), "dd.MM.yyyy")}
          </td>
        </>
      )}
    </tr>
  )
}

export const ApiaryEventsTableRow = ({ event }: { event: ApiaryEvent }) => {
  const { data: eventTypes } = useGetAllApiaryEventTypesQuery()

  return (
    <ApiaryEventTableRow
      event={event}
      eventTypes={eventTypes ?? []}
      routePath={APIARY_EVENTS_ROUTE}
    />
  )
}
