interface TransactionCardWrapperProps {
  onClick?: () => void
  children: React.ReactNode
}

const TransactionCardWrapper: React.FC<TransactionCardWrapperProps> = ({
  onClick,
  children,
}) => {
  return (
    <div
      className="fp-border box-border flex min-h-[55px] w-full items-center justify-between gap-[10px] rounded-[var(--fp-border-radius)] bg-white px-[15px] py-[7px]"
      onClick={onClick}
    >
      {children}
    </div>
  )
}

export default TransactionCardWrapper
