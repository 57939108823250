import React, { type ChangeEvent, useEffect, useMemo, useCallback } from "react"
import { useTranslation } from "react-i18next"
import { HivePositionIcon } from "@/assets/icons/misc/hive-position-icon.tsx"
import WeightScaleIcon from "@/assets/icons/misc/weight-scale-icon.tsx"
import InputFieldWrapperWithIcon from "@/components/text-card-wrapper-with-icon/input-field-wrapper-with-icon.tsx"
import useCheckHiveEventType from "@/features/events/hive-events/hooks/use-check-hive-event-type.ts"
import {
  EQUIPMENT_ACTINS,
  HIVE_EQUIPMENT_EVENTS_ENUM,
  HIVE_EQUIPMENT_TYPES_ENUM,
} from "@/features/events/hive-events/types/hive-events-constants.ts"
import { useAppDispatch } from "@/redux/hooks.ts"
import {
  type HiveEventDraft,
  setHiveDraftEventSubDataAction,
  setHiveDraftEventSubDataEquipmentType,
  setHiveDraftEventSubDataPosition,
  setHiveDraftEventSubDataQuantity,
} from "@/redux/slices/hive-events-draft-slice.ts"

interface Props {
  draftEvent: HiveEventDraft
}

export const HiveEventEquipmentEditor: React.FC<Props> = ({ draftEvent }) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const checkEventType = useCheckHiveEventType()

  const eventChecks = useMemo(
    () => ({
      isAddingFrames: checkEventType(
        draftEvent.type,
        HIVE_EQUIPMENT_EVENTS_ENUM.ADD_FRAMES,
      ),
      isRemovingFrames: checkEventType(
        draftEvent.type,
        HIVE_EQUIPMENT_EVENTS_ENUM.REMOVE_FRAMES,
      ),
      isAddingSupers: checkEventType(
        draftEvent.type,
        HIVE_EQUIPMENT_EVENTS_ENUM.ADD_SUPER,
      ),
      isRemovingSupers: checkEventType(
        draftEvent.type,
        HIVE_EQUIPMENT_EVENTS_ENUM.REMOVE_SUPER,
      ),
      isAddingBroodBox: checkEventType(
        draftEvent.type,
        HIVE_EQUIPMENT_EVENTS_ENUM.ADD_BROOD_BOX,
      ),
      isRemovingBroodBox: checkEventType(
        draftEvent.type,
        HIVE_EQUIPMENT_EVENTS_ENUM.REMOVE_BROOD_BOX,
      ),
    }),
    [draftEvent.type, checkEventType],
  )

  useEffect(() => {
    const currentEquipmentType = draftEvent.event_data?.equipment_type
    const currentAction = draftEvent.event_data?.action

    const {
      isAddingFrames,
      isRemovingFrames,
      isAddingSupers,
      isRemovingSupers,
      isAddingBroodBox,
      isRemovingBroodBox,
    } = eventChecks

    let newEquipmentType = null
    let newAction = null

    if (isAddingFrames || isRemovingFrames) {
      newEquipmentType = HIVE_EQUIPMENT_TYPES_ENUM.FRAME
    } else if (isAddingSupers || isRemovingSupers) {
      newEquipmentType = HIVE_EQUIPMENT_TYPES_ENUM.SUPER
    } else if (isAddingBroodBox || isRemovingBroodBox) {
      newEquipmentType = HIVE_EQUIPMENT_TYPES_ENUM.BROOD_BOX
    }

    if (isAddingFrames || isAddingSupers || isAddingBroodBox) {
      newAction = EQUIPMENT_ACTINS.ADD
    } else if (isRemovingFrames || isRemovingSupers || isRemovingBroodBox) {
      newAction = EQUIPMENT_ACTINS.REMOVE
    }

    if (newEquipmentType && newEquipmentType !== currentEquipmentType) {
      dispatch(setHiveDraftEventSubDataEquipmentType(newEquipmentType))
    }

    if (newAction && newAction !== currentAction) {
      dispatch(setHiveDraftEventSubDataAction(newAction))
    }
  }, [dispatch, eventChecks, draftEvent.event_data])

  const setEquipmentQuantity = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value
      if (/^\d*$/.test(value)) {
        if (+value < 0) return
        dispatch(setHiveDraftEventSubDataQuantity(event.target.value))
      }
    },
    [dispatch],
  )

  const setEquipmentPosition = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const position = event.target.value
      dispatch(setHiveDraftEventSubDataPosition(position))
    },
    [dispatch],
  )

  return (
    <>
      <div className="mt-2 text-[14px] text-neutral-400">
        {t("specificEventDetails")}
      </div>
      <InputFieldWrapperWithIcon
        icon={<WeightScaleIcon className="w-[30px] text-white" />}
        align="row"
        name={t("value")}
        maxLength={14}
        inputMode="numeric"
        isDisabled={false}
        value={draftEvent.event_data?.quantity}
        isRequired={!draftEvent.event_data?.quantity}
        onChange={setEquipmentQuantity}
        details={t("pieces")}
      />
      <InputFieldWrapperWithIcon
        icon={<HivePositionIcon className={"w-[30px]"} />}
        align="row"
        name={t("inHivePosition")}
        isDisabled={false}
        isRequired={!draftEvent.event_data?.position}
        value={draftEvent.event_data?.position ?? ""}
        onChange={setEquipmentPosition}
      />
    </>
  )
}
