import { format } from "date-fns"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import PlaceholderIcon from "@/assets/icons/misc/placeholder-icon.tsx"
import GenericPicture from "@/components/generic-picture/generic-picture.tsx"
import Skeleton from "@/components/skeleton/skeleton.tsx"
import TransactionCardSkeleton from "@/features/finance/components/transaction-card-skeleton/transaction-card-skeleton.tsx"
import useGetTransactionCategoryById from "@/features/finance/hooks/use-get-transaction-category-by-id.ts"
import { type Transaction } from "@/features/finance/types/transaction.ts"
import { FINANCE_TRANSACTIONS_SCREEN_PATH } from "@/utils/constants/routes.ts"

interface TransactionCardProps {
  transaction: Transaction
}

const TransactionCard: React.FC<TransactionCardProps> = ({ transaction }) => {
  const { t } = useTranslation()
  const { transactionCategory, isLoading, isError } =
    useGetTransactionCategoryById(transaction.category)
  const navigate = useNavigate()

  const handleNavigate = () => {
    navigate(FINANCE_TRANSACTIONS_SCREEN_PATH + `/${transaction.id}`)
  }

  const [isPictureLoading, setIsPictureLoading] = useState<boolean>(true)

  if (isError || isLoading) {
    return (
      <Skeleton
        className={"min-h-[55px] w-full rounded-[var(--fp-border-radius)]"}
      />
    )
  }

  const handleImageLoaded = () => {
    setIsPictureLoading(false)
  }

  return (
    <TransactionCardSkeleton
      onClick={handleNavigate}
      icon={
        transactionCategory?.picture ? (
          <div style={{ position: "relative", width: 40, height: 40 }}>
            <GenericPicture
              src={transactionCategory.picture}
              alt={""}
              size={40}
              borderRadius={transactionCategory.farm ? 8 : 0}
              contain={!transactionCategory.farm}
              onImageLoad={handleImageLoaded}
            />
            {isPictureLoading && (
              <Skeleton
                className="absolute left-0 top-0 rounded-[8px]"
                height={"100%"}
                width={"100%"}
              />
            )}
          </div>
        ) : (
          <PlaceholderIcon size={40} />
        )
      }
      topLeftText={transactionCategory && t(transactionCategory.name)}
      topRightText={transaction.amount}
      bottomRightText={transaction.currency}
      bottomLeftText={format(transaction.date, "dd.MM.yyyy")}
      isOutflow={transactionCategory?.is_outflow}
    />
  )
}

export default TransactionCard
