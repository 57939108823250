import isEqual from "lodash.isequal"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"
import DeleteButton from "@/components/delete-button/delete-button.tsx"
import { LoadingAnimation } from "@/components/loading-animation/loading-animation.tsx"
import PageContentWrapperLarge from "@/components/page-content-wrappers/page-content-wrapper-large.tsx"
import EditorTopBar from "@/components/top-bars/editor-top-bar/editor-top-bar.tsx"
import {
  useDeleteHiveEventMutation,
  useUpdateHiveEventMutation,
} from "@/features/events/hive-events/api/hive-events-api.ts"
import { HiveEventEditor } from "@/features/events/hive-events/components/hive-event-editor/hive-event-editor.tsx"
import useGetDraftHiveEvent from "@/features/events/hive-events/hooks/use-get-draft-hive-event.ts"
import { useGetHiveEventDataTypeObject } from "@/features/events/hive-events/hooks/use-get-hive-event-data-type-object.ts"
import { validateHiveEvent } from "@/features/events/hive-events/types/hive-event-schema.tsx"
import useModal from "@/features/modals/hooks/use-modal.ts"
import { MODALS } from "@/features/modals/types/modals.tsx"
import useNavigationFallback from "@/hooks/use-navigation-fallback.ts"
import { useAppDispatch } from "@/redux/hooks.ts"
import { resetHiveEventDraft } from "@/redux/slices/hive-events-draft-slice.ts"

export const HiveEventView = () => {
  const { hiveEventId } = useParams()
  const { openModal } = useModal()
  const dispatch = useAppDispatch()
  const { handleBackNavigation } = useNavigationFallback()
  const [deleteHiveEventMutation] = useDeleteHiveEventMutation()
  const [updateHiveEventMutation] = useUpdateHiveEventMutation()
  const { t } = useTranslation()
  const { data, isLoading, draftEvent } = useGetDraftHiveEvent(hiveEventId)
  const hasChanges = !!data && !isEqual(draftEvent, data)
  const hiveEventDataTypeObject = useGetHiveEventDataTypeObject(draftEvent.type)

  const deleteHiveEvent = async () => {
    if (data) {
      await toast
        .promise(deleteHiveEventMutation(data).unwrap(), {
          pending: t("deletingHiveEvent"),
          success: t("hiveEventDeleted"),
          error: t("hiveEventDeleteError"),
        })
        .then(() => {
          dispatch(resetHiveEventDraft({}))
          handleBackNavigation()
        })
    }
  }

  const handleDelete = () => {
    openModal(MODALS.BASE_MODAL, {
      title: t("deleteAnimalEventConfirmation"),
      content: t("eventWillBeDeleted"),
      onActionClick: deleteHiveEvent,
    })
  }

  const handleUpdate = async () => {
    if (
      hiveEventId &&
      (await validateHiveEvent(draftEvent, hiveEventDataTypeObject))
    ) {
      await toast
        .promise(
          updateHiveEventMutation({
            id: hiveEventId,
            ...draftEvent,
          }).unwrap(),
          {
            pending: t("updatingHiveEvent"),
            success: t("hiveEventUpdated"),
            error: t("hiveEventUpdateError"),
          },
        )
        .then(() => handleBackNavigation())
        .catch((err) => {
          const errorDetail = err.data?.detail
          const errorMessage = errorDetail
            ? errorDetail.split(":").slice(1).join(":").trim()
            : "errorCreatingHiveEvent"

          toast.error(t(errorMessage))
        })
    }
  }

  useEffect(() => {
    return () => {
      dispatch(resetHiveEventDraft({}))
    }
  }, [dispatch])

  return (
    <>
      <EditorTopBar
        text={"hiveEvent"}
        isModified={hasChanges}
        onBackClick={handleBackNavigation}
        onSave={handleUpdate}
      />
      <PageContentWrapperLarge>
        {isLoading || !draftEvent ? (
          <LoadingAnimation />
        ) : (
          <>
            <HiveEventEditor
              eventId={hiveEventId}
              draftHiveEvent={draftEvent}
            />
            <div className={"mt-10"}>
              <DeleteButton
                text={t("deleteAnimalEvent")}
                onClick={handleDelete}
              />
            </div>
          </>
        )}
      </PageContentWrapperLarge>
    </>
  )
}
