import { useMemo } from "react"
import { useGetTransactionCategoriesQuery } from "@/features/finance/api/transaction-categories-api"

const useGetTransactionCategoryById = (category: string | null | undefined) => {
  const {
    data: transactionCategories,
    isLoading,
    isError,
  } = useGetTransactionCategoriesQuery()

  return useMemo(() => {
    const transactionCategory =
      transactionCategories && category
        ? transactionCategories[category]
        : undefined

    return {
      transactionCategory,
      isLoading,
      isError,
    }
  }, [transactionCategories, category, isLoading, isError])
}

export default useGetTransactionCategoryById
