import React from "react"
import { DARK_GREY, WHITE } from "@/utils/constants/colors-constants.ts"
import type GeneralSvgProps from "@/utils/types/sgv/general-svg-props.ts"

interface ArrowDownProps extends GeneralSvgProps {
  active?: boolean
  isDown?: boolean
}

const ArrowDown: React.FC<ArrowDownProps> = ({
  active = false,
  isDown = true,
  ...props
}) => {
  const color = active ? WHITE : DARK_GREY
  const rotation = isDown ? "0deg" : "180deg"

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="7.85"
      height="5.509"
      viewBox={"0 0 8 6"}
      style={{ transform: `rotate(${rotation})` }}
      fill={"currentColor"}
      {...props}
    >
      <path
        d="M.115.182a.645.645 0 0 0 0 .735l3.243 4.289c.307.404.803.404 1.11 0L7.735.883A.641.641 0 0 0 7.74.157c-.152-.206-.404-.21-.559-.005L4.19 4.105c-.153.202-.401.202-.555 0L.67.182C.516-.02.27-.02.115.182"
        fill={color || "currentColor"}
        fillRule="evenodd"
        data-name="arrow_down-[#338]"
      />
    </svg>
  )
}

export default ArrowDown
