import type GeneralSvgProps from "@/utils/types/sgv/general-svg-props.ts"

const UserIcon: React.FC<GeneralSvgProps> = (props) => (
  <svg
    width={props?.size || "64px"}
    height={props?.size || "64px"}
    viewBox="-2.4 -2.4 28.80 28.80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    stroke="#ffffff"
  >
    <g id="SVGRepo_bgCarrier" strokeWidth="0">
      <rect
        x="-2.4"
        y="-2.4"
        width="28.80"
        height="28.80"
        rx="5.76"
        fill="#cf7eec"
        strokeWidth="0"
      ></rect>
    </g>
    <g
      id="SVGRepo_tracerCarrier"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      <path
        d="M5 21C5 17.134 8.13401 14 12 14C15.866 14 19 17.134 19 21M16 7C16 9.20914 14.2091 11 12 11C9.79086 11 8 9.20914 8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7Z"
        stroke="#ffffff"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
    </g>
  </svg>
)

export default UserIcon
