import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import apiaryEventsImg from "@/assets/images/apiary-event-widget-background-image.png"
import hiveEventsImg from "@/assets/images/hive-event-widget-background-image.png"
// import queenEventsImg from "@/assets/images/queen-event-widget-background-image.png"
import PageContentWrapperLarge from "@/components/page-content-wrappers/page-content-wrapper-large.tsx"
import MobileTopBar from "@/components/top-bars/mobile-top-bar/mobile-top-bar.tsx"
import {
  APIARY_EVENTS_ROUTE,
  HIVE_EVENTS_ROUTE,
} from "@/utils/constants/routes.ts"

const BeeKeepingEvents = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <>
      <MobileTopBar title={t("beeKeepingEvents")} />
      <PageContentWrapperLarge className={"flex flex-col gap-3 p-4"}>
        <div
          className={
            "widget-border relative box-border flex min-h-24 flex-col gap-5 overflow-hidden bg-white p-4"
          }
          style={{
            background:
              "radial-gradient(84.22% 66.11% at 95.19% 64.17%, #FFE9CD 23.78%, #FFFCF7 100%)",
          }}
          onClick={() => navigate(APIARY_EVENTS_ROUTE)}
        >
          <div className={"w-3/5"}>
            <div className={"text-[16px] font-semibold text-neutral-700"}>
              {t("apiaryEvents")}
            </div>
            <div className={"font-semibold text-brown-500"}>
              {t("apiaryEventsDescription")}
            </div>
          </div>
          <img
            src={apiaryEventsImg}
            alt=""
            className="absolute bottom-0 right-0 h-full object-contain"
          />
        </div>
        <div
          className={
            "widget-border relative box-border flex min-h-24 flex-col gap-5 overflow-hidden bg-white p-4"
          }
          style={{
            background:
              "radial-gradient(84.22% 66.11% at 95.19% 64.17%, #FFE9CD 23.78%, #FFFCF7 100%)",
          }}
          onClick={() => navigate(HIVE_EVENTS_ROUTE)}
        >
          <div className={"w-3/5"}>
            <div className={"text-[16px] font-semibold text-neutral-700"}>
              {t("hiveEvents")}
            </div>
            <div className={"font-semibold text-brown-500"}>
              {t("hiveEventsDescription")}
            </div>
          </div>
          <img
            src={hiveEventsImg}
            alt=""
            className="absolute bottom-0 right-0 h-full object-contain"
          />
        </div>
        {/*<div*/}
        {/*  className={*/}
        {/*    "widget-border relative box-border flex min-h-24 flex-col gap-5 overflow-hidden bg-white p-4"*/}
        {/*  }*/}
        {/*  style={{*/}
        {/*    background:*/}
        {/*      "radial-gradient(84.22% 66.11% at 95.19% 64.17%, #FFE9CD 23.78%, #FFFCF7 100%)",*/}
        {/*  }}*/}
        {/*  onClick={() => navigate(HIVE_EVENTS_ROUTE)}*/}
        {/*>*/}
        {/*  <div className={"w-3/5"}>*/}
        {/*    <div className={"text-[16px] font-semibold text-neutral-700"}>*/}
        {/*      {t("queenEvents")}*/}
        {/*    </div>*/}
        {/*    <div className={"font-semibold text-brown-500"}>*/}
        {/*      {t("queenEventsDescription")}*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*  <img*/}
        {/*    src={queenEventsImg}*/}
        {/*    alt=""*/}
        {/*    className="absolute bottom-0 right-0 h-full object-contain"*/}
        {/*  />*/}
        {/*</div>*/}
      </PageContentWrapperLarge>
    </>
  )
}

export default BeeKeepingEvents
