import { toast } from "react-toastify"
import * as Yup from "yup"
import { type ApiaryEventInput } from "@/features/events/apiary-events/api/apiary-events-api.ts"
import CustomErrorToast from "@/utils/toast-config/error-toast.tsx"

const baseMovementSchema = {
  from_location: Yup.string().required("fromLocationRequired"),
  to_location: Yup.string().required("toLocationRequired"),
  is_permanent: Yup.boolean(),
  departure_date: Yup.date()
    .nullable()
    .transform((_value, originalValue) =>
      originalValue === null ||
      originalValue === "" ||
      isNaN(new Date(originalValue).getTime())
        ? null
        : new Date(originalValue),
    )
    .required("departureDateRequired"),
}

const baseEventSchema = {
  apiary: Yup.string().required("apiaryRequired"),
  type: Yup.string().required("eventTypeRequired"),
  person_name: Yup.string()
    .max(255, "personNameMaxLength")
    .nullable()
    .transform((value) => (value === "" ? null : value)),
  date: Yup.date()
    .nullable()
    .transform((_value, originalValue) =>
      originalValue === null ||
      originalValue === "" ||
      isNaN(new Date(originalValue).getTime())
        ? null
        : new Date(originalValue),
    )
    .required("dateRequiredEvent"),
  description: Yup.string()
    .max(1000, "descriptionMaxLength")
    .nullable()
    .transform((value) => (value === "" ? null : value)),
  notes: Yup.string()
    .max(1000, "notesMaxLength")
    .nullable()
    .transform((value) => (value === "" ? null : value)),
}

const movementDataSchema = Yup.object().shape(baseMovementSchema)
const pastoralMovementDataSchema = Yup.object().shape({
  ...baseMovementSchema,
  flora_type: Yup.string().required("floraTypeRequired"),
})

export const getApiaryEventSchema = (
  isMovementEvent: boolean,
  isPastoralEvent?: boolean,
) => {
  const selectedEventDataSchema = Yup.lazy(() => {
    if (isPastoralEvent) return pastoralMovementDataSchema
    if (isMovementEvent) return movementDataSchema
    return Yup.mixed().notRequired()
  })

  return Yup.object().shape({
    ...baseEventSchema,
    event_data: selectedEventDataSchema,
  })
}

export const validateApiaryEvent = async (
  apiaryEvent: Partial<ApiaryEventInput> = {},
  isMovementEvent: boolean,
  isPastoralEvent?: boolean,
) => {
  const apiaryEventSchema = getApiaryEventSchema(
    isMovementEvent,
    isPastoralEvent,
  )

  try {
    await apiaryEventSchema.validate(apiaryEvent, { abortEarly: false })
    return true
  } catch (error) {
    if (error instanceof Yup.ValidationError) {
      toast.error(<CustomErrorToast errors={error.errors} />)
    }
    return false
  }
}
