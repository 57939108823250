import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { AttachImageFieldBase64 } from "@/components/attach-image-field/attach-image-field-base-64.tsx"
import CustomSelectDropdown from "@/components/custom-select/custom-select-dropdown.tsx"
import DeleteButton from "@/components/delete-button/delete-button.tsx"
import GenericTypeDrawerHeader from "@/components/generic-type-drawer/generic-type-drawer-header/generic-type-drawer-header.tsx"
import OverflowMenuItem from "@/components/task-card/overflow-menu-item.tsx"
import {
  useCreateAnimalEventTypeMutation,
  useDeleteAnimalEventTypeMutation,
  useGetAllAnimalEventTypesQuery,
  useUpdateAnimalEventTypeMutation,
} from "@/features/events/animal-events/api/event-types-api.ts"
import useGetEventTypeParents from "@/features/events/animal-events/hooks/use-get-event-type-parents.ts"
import { type AnimalEventType } from "@/features/events/animal-events/types/animal-events.ts"
import RequiredIndicator from "@/features/farm/components/required-indicator/required-indicator.tsx"
import styles from "@/features/finance/components/add-transaction-category-form/add-transaction-category-form.module.css"
import useModal from "@/features/modals/hooks/use-modal.ts"
import { MODALS } from "@/features/modals/types/modals.tsx"
import { getObjectDifference } from "@/utils/helpers/object-utils.ts"

interface AddTransactionCategoryFormProps {
  onBackClick: () => void
  eventId: string | null
}

const initialEventType: Partial<AnimalEventType> = {
  name: "",
  picture: null,
  parent: "",
}

const AddEventTypeForm: React.FC<AddTransactionCategoryFormProps> = ({
  onBackClick,
  eventId,
}) => {
  const { t } = useTranslation()
  const { openModal } = useModal()
  const { data } = useGetAllAnimalEventTypesQuery()
  const eventTypesParents = useGetEventTypeParents()
  const eventTypeParentsOptions = eventTypesParents?.map((parent) => ({
    value: parent.id,
    label: t(parent.name),
  }))
  const eventType = eventId && data?.[eventId]

  const [createEventType] = useCreateAnimalEventTypeMutation()
  const [updateEventType] = useUpdateAnimalEventTypeMutation()
  const [deleteEventType] = useDeleteAnimalEventTypeMutation()
  const [newEventType, setNewEventType] = useState(
    eventType || initialEventType,
  )

  const handlePictureChange = (imgFile: string | null) => {
    setNewEventType({ ...newEventType, picture: imgFile })
  }

  const handleSaveEventType = () => {
    toast
      .promise(createEventType(newEventType).unwrap(), {
        pending: t("savingEventType"),
        success: t("eventTypeSaved"),
        error: t("errorCreatingEventType"),
      })
      .then(() => {
        onBackClick()
      })
  }

  const diff = eventType && getObjectDifference(eventType, newEventType)

  const handleUpdateEventType = () => {
    if (eventType) {
      toast
        .promise(
          updateEventType({
            ...diff,
            id: eventType.id,
          }).unwrap(),
          {
            pending: t("updatingEventType"),
            success: t("eventTypeUpdated"),
            error: t("errorUpdatingEventType"),
          },
        )
        .then(() => {
          onBackClick()
        })
    }
  }

  const handleDeleteEventType = () => {
    if (!eventType) {
      return
    }

    openModal(MODALS.BASE_MODAL, {
      title: t("deleteEventTypeConfirmation"),
      content: t("eventTypeWillBeDeleted"),
      onActionClick: () => {
        toast
          .promise(deleteEventType(eventType?.id).unwrap(), {
            pending: t("deletingEventType"),
            success: t("eventTypeDeleted"),
            error: t("errorDeletingEventType"),
          })
          .then(() => {
            onBackClick()
          })
      },
    })
  }

  const handleEventTypeMutation = () => {
    if (eventType) {
      handleUpdateEventType()
    } else {
      handleSaveEventType()
    }
  }

  const handleEventTypeParentChange = (parent: string) => {
    setNewEventType({ ...newEventType, parent })
  }
  return (
    <div className={styles.drawerFinanceContentWrapper}>
      <GenericTypeDrawerHeader
        title={eventType ? t(eventType.name) : t("addEventType")}
        onBackClick={onBackClick}
        {...((diff && Object.keys(diff).length > 0) || !eventType
          ? { onSaveClick: handleEventTypeMutation }
          : {})}
        closeOnSave={false}
      />
      <div className={styles.formBody}>
        <OverflowMenuItem>
          <input
            type="text"
            placeholder={t("eventName")}
            className={styles.input}
            value={newEventType.name}
            onChange={(e) =>
              setNewEventType({
                ...newEventType,
                name: e.target.value,
              })
            }
            required
            maxLength={100}
          />
          {newEventType.name === "" && <RequiredIndicator />}
        </OverflowMenuItem>
        <AttachImageFieldBase64
          picture={newEventType.picture as string}
          onChoosePicture={handlePictureChange}
        />
        {eventTypeParentsOptions && (
          <OverflowMenuItem>
            <CustomSelectDropdown
              placeholder={t("chooseEventTypeParent")}
              onSelectionChange={handleEventTypeParentChange}
              currentSelection={newEventType.parent}
              options={eventTypeParentsOptions}
            />
          </OverflowMenuItem>
        )}
      </div>
      {eventType && (
        <DeleteButton
          text={t("deleteEventType")}
          onClick={handleDeleteEventType}
        />
      )}
    </div>
  )
}

export default AddEventTypeForm
