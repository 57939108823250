import {
  type HiveResponse,
  type HiveSummary,
} from "@/features/bees/types/hives.ts"

export interface Apiary {
  id: string
  name: string
  address: string
  registration_code: string
  latitude: string
  longitude: string
  description: string
  picture: string | null
  created_at: string
  updated_at: string
  farm: string
  tasks: string[]
  notes: string[]
}

export type ApiaryInput = Omit<
  Apiary,
  | "id"
  | "created_at"
  | "updated_at"
  | "tasks"
  | "notes"
  | "farm"
  | "latitude"
  | "longitude"
>

export const apiaryInputInitialState: ApiaryInput = {
  registration_code: "",
  name: "",
  description: "",
  address: "",
  picture: "",
}

export interface ApiarySummaryResponse {
  id: string
  name: string
  address: string
  hives: HiveSummary[]
}

export interface ApiaryResponse extends Apiary {
  hives: HiveResponse[]
}
