import { useGetAllApiaryEventTypesQuery } from "@/features/events/apiary-events/api/apiary-event-types-api.ts"
import { type APIARY_EVENT_TYPES_ENUM } from "@/features/events/apiary-events/types/apiary-events-constants.ts"

const useCheckApiaryEventType = () => {
  const { data: eventTypes } = useGetAllApiaryEventTypesQuery()

  return (eventId: string | undefined, type: APIARY_EVENT_TYPES_ENUM) => {
    if (!eventId || !eventTypes) return false

    const eventType = eventTypes.find((event) => event.id === eventId)
    if (!eventType) return false

    const isType = eventType.name === type

    const isParentType =
      eventType.parent &&
      eventTypes.find((event) => event.id === eventType.parent)?.name === type

    return Boolean(isType || isParentType)
  }
}

export default useCheckApiaryEventType
