import { useState } from "react"
import { useTranslation } from "react-i18next"
import DrawerContentWrapper from "@/components/drawer-content-wrapper/drawer-content-wrapper.tsx"
import GenericTypeDrawerHeader from "@/components/generic-type-drawer/generic-type-drawer-header/generic-type-drawer-header.tsx"
import EventsTypeDropdown from "@/features/events/animal-events/components/event-type-drawer/events-type-dropdown.tsx"
import { type AnimalEventType } from "@/features/events/animal-events/types/animal-events.ts"

interface GenericTypeDrawerContentProps {
  selectedEvent: string | undefined
  onEventSelect: (eventType: string | undefined) => void
  eventTypesMap: Map<string, AnimalEventType[]>
  onViewChange: (entityId: string) => void
}

const EventTypeDrawerContent: React.FC<GenericTypeDrawerContentProps> = ({
  selectedEvent,
  onEventSelect,
  eventTypesMap,
  onViewChange,
}) => {
  const { t } = useTranslation()

  const [openArray, setOpenArray] = useState(
    Array.from({ length: eventTypesMap.size }, () => false),
  )

  const toggleDrawer = (indexToToggle: number) => {
    setOpenArray(
      openArray.map((isOpen, index) =>
        index === indexToToggle ? !isOpen : false,
      ),
    )
  }

  const handleEventSelect = (eventType: string | undefined) => {
    onEventSelect(eventType)
  }

  return (
    <>
      <GenericTypeDrawerHeader
        title={t("selectEventType")}
        onSaveClick={() => {}}
      />
      <DrawerContentWrapper>
        {Array.from(eventTypesMap.entries()).map((entry, key) => (
          <EventsTypeDropdown
            eventTypes={entry}
            key={key}
            isOpen={openArray[key]}
            onOpen={() => toggleDrawer(key)}
            selectedEvent={selectedEvent}
            onClick={(eventTypeId) => handleEventSelect(eventTypeId)}
            onViewChange={onViewChange}
          />
        ))}
      </DrawerContentWrapper>
    </>
  )
}

export default EventTypeDrawerContent
