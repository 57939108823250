import React from "react"
import { type DateRange } from "react-day-picker"
import { useTranslation } from "react-i18next"
import { FilterScroll } from "@/components/filter-scroll/filter-scroll.tsx"
import { SelectPill } from "@/components/select-pill/select-pill.tsx"
import { SelectionRowDrawer } from "@/components/selection-row-drawer/selection-row-drawer.tsx"
import { DrawerTrigger } from "@/components/shadcn/drawer.tsx"
import { useGetApiariesQuery } from "@/features/bees/api/apiaries-api.ts"
import { useGetAllHivesQuery } from "@/features/bees/api/hives-api.ts"
import { HIVES_FILTER_ENUM } from "@/features/bees/utils/hive-filter.ts"
import useMapEventTypesToParents from "@/features/events/animal-events/hooks/use-map-event-types-to-parents.ts"
import { type AnimalEventType } from "@/features/events/animal-events/types/animal-events.ts"
import { CommonEventsDrawer } from "@/features/events/common/components/common-events-drawer/common-events-drawer.tsx"
import { DateFilterDrawer } from "@/features/events/common/components/date-filter-drawer/date-filter-drawer.tsx"
import { useGetAllHiveEventTypesQuery } from "@/features/events/hive-events/api/hive-event-types-api.ts"

interface Props {
  selectedApiary: string
  selectedHive: string
  selectedEventType: string
  selectedTimeFrame: DateRange | undefined
  onSelectedApiaryChange: (apiary: string) => void
  onSelectedHiveChange: (hive: string) => void
  onSelectedTimeFrameChange: (dateRange: DateRange | undefined) => void
  onSelectedEventTypeChange: (eventType: string) => void
}

export const HiveEventsFilter: React.FC<Props> = ({
  selectedApiary,
  selectedHive,
  selectedEventType,
  selectedTimeFrame,
  onSelectedApiaryChange,
  onSelectedHiveChange,
  onSelectedEventTypeChange,
  onSelectedTimeFrameChange,
}) => {
  const { data: apiaries } = useGetApiariesQuery()
  const { data: hives } = useGetAllHivesQuery()
  const { data: hivesEventTypes } = useGetAllHiveEventTypesQuery()
  const { t } = useTranslation()
  const eventTypesMap = hivesEventTypes?.reduce<
    Record<string, AnimalEventType>
  >((acc, eventType) => {
    return { ...acc, [eventType.id]: eventType as AnimalEventType }
  }, {})
  const parentEventTypes = useMapEventTypesToParents(eventTypesMap)
  const apiariesOptions = [
    {
      value: HIVES_FILTER_ENUM.ALL_APIARIES,
      label: HIVES_FILTER_ENUM.ALL_APIARIES,
    },
    ...(apiaries
      ? apiaries.map((apiary) => ({
          value: apiary.id,
          label: apiary.name,
        }))
      : []),
  ]
  const hivesOptions = [
    {
      value: HIVES_FILTER_ENUM.ALL_HIVES,
      label: HIVES_FILTER_ENUM.ALL_HIVES,
    },
    ...(hives
      ? hives.map((hive) => ({
          value: hive.id,
          label: hive.code,
        }))
      : []),
  ]

  const handleApiaryChange = (apiaryId: string) => {
    onSelectedApiaryChange(apiaryId)
  }

  const handleHiveChange = (hiveId: string) => {
    onSelectedHiveChange(hiveId)
  }

  const handleEventTypeChange = (eventType: string | undefined) => {
    onSelectedEventTypeChange(eventType || "")
  }

  const selectedApiaryOption = apiariesOptions.find(
    (option) => option.value === selectedApiary,
  )

  const selectedHiveOption = hivesOptions.find(
    (option) => option.value === selectedHive,
  )

  return (
    <FilterScroll>
      <SelectionRowDrawer
        multiple={false}
        renderTrigger={
          <DrawerTrigger>
            <SelectPill
              isActive={selectedApiary !== HIVES_FILTER_ENUM.ALL_APIARIES}
            >
              <div>
                {selectedApiaryOption
                  ? t(selectedApiaryOption?.label)
                  : t(apiariesOptions[0].label)}
              </div>
            </SelectPill>
          </DrawerTrigger>
        }
        title={t("selectApiary")}
        options={apiariesOptions}
        selectedValues={selectedApiary}
        onSelectionChange={handleApiaryChange}
      />
      <SelectionRowDrawer
        multiple={false}
        renderTrigger={
          <DrawerTrigger>
            <SelectPill isActive={selectedHive !== HIVES_FILTER_ENUM.ALL_HIVES}>
              <div>
                {selectedHiveOption
                  ? t(selectedHiveOption?.label)
                  : t(hivesOptions[0].label)}
              </div>
            </SelectPill>
          </DrawerTrigger>
        }
        title={t("selectHive")}
        options={hivesOptions}
        selectedValues={selectedHive}
        onSelectionChange={handleHiveChange}
      />
      <CommonEventsDrawer
        eventTypesMap={parentEventTypes}
        fallbackText={t("selectEventType")}
        onEventSelect={handleEventTypeChange}
        selectedValue={selectedEventType}
      />
      <DateFilterDrawer
        selectedTimeFrame={selectedTimeFrame}
        onSelectedTimeFrameChange={onSelectedTimeFrameChange}
      />
    </FilterScroll>
  )
}
