import { useState } from "react"
import { LoadingAnimation } from "@/components/loading-animation/loading-animation.tsx"
import PageContentWrapperLarge from "@/components/page-content-wrappers/page-content-wrapper-large.tsx"
import MobileTopBar from "@/components/top-bars/mobile-top-bar/mobile-top-bar.tsx"
import { cn } from "@/utils/styles.ts"

export const TractoareRo = () => {
  const [isLoaded, setIsLoaded] = useState(false)
  const iframeClasses = cn("h-full w-full", !isLoaded && "hidden")

  return (
    <>
      <MobileTopBar title={"Tractoare-ro"} />
      <PageContentWrapperLarge className={"p-0"}>
        <iframe
          src="https://tractoare-ro.ro/"
          className={iframeClasses}
          title="Tractoare.ro"
          onLoad={() => setIsLoaded(true)}
        />
        {!isLoaded && <LoadingAnimation />}
      </PageContentWrapperLarge>
    </>
  )
}
