import { apiSlice, TagType } from "@/features/api/apiSlice.ts"
import {
  type ApiaryInput,
  type ApiaryResponse,
  type ApiarySummaryResponse,
} from "@/features/bees/types/apiaries.ts"

const APIARIES_API_BASE_URL = "/apiaries/"

export const apiariesApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createApiary: builder.mutation<ApiaryResponse, ApiaryInput>({
      query: (apiary) => ({
        url: APIARIES_API_BASE_URL,
        method: "POST",
        body: apiary,
      }),
      invalidatesTags: [{ type: TagType.Apiaries, id: "LIST" }],
    }),
    getApiaries: builder.query<ApiarySummaryResponse[], void>({
      query: () => APIARIES_API_BASE_URL,
      providesTags: [{ type: TagType.Apiaries, id: "LIST" }],
    }),
    getApiaryById: builder.query<ApiaryResponse, string>({
      query: (id) => APIARIES_API_BASE_URL + `${id}/`,
      providesTags: (_result, _error, id) => [{ type: TagType.Apiaries, id }],
    }),
    deleteApiary: builder.mutation<void, string>({
      query: (id) => ({
        url: APIARIES_API_BASE_URL + `${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: (_queryCache, _error, apiaryId) => [
        { type: TagType.Apiaries, id: "LIST" },
        { type: TagType.Apiaries, id: apiaryId },
        { type: TagType.Hives },
        { type: TagType.Queens },
      ],
    }),
    updateApiary: builder.mutation<
      ApiaryResponse,
      { apiary: Partial<ApiaryInput>; apiaryId: string }
    >({
      query: ({ apiary, apiaryId }) => ({
        url: APIARIES_API_BASE_URL + `${apiaryId}/`,
        method: "PATCH",
        body: apiary,
      }),
      invalidatesTags: (_queryCache, _error, { apiaryId }) => [
        { type: TagType.Apiaries, id: "LIST" },
        { type: TagType.Apiaries, id: apiaryId },
      ],
    }),
  }),
})

export const {
  useCreateApiaryMutation,
  useDeleteApiaryMutation,
  useUpdateApiaryMutation,
  useGetApiariesQuery,
  useGetApiaryByIdQuery,
} = apiariesApi
