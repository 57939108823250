import isEqual from "lodash.isequal"
import { useLayoutEffect } from "react"
import { useDispatch } from "react-redux"
import { useSearchParams } from "react-router-dom"
import { APIARY_QUERY_PARAMS } from "@/features/events/common/types/apiary-querry-params.ts"
import { useAppSelector } from "@/redux/hooks.ts"
import {
  initialApiaryEventState,
  resetApiaryEventDraft,
  setDraftApiaryEventApiary,
} from "@/redux/slices/apiary-events-slice.ts"

const useInitializeApiaryEventDraft = () => {
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()
  const apiaryId = searchParams.get(APIARY_QUERY_PARAMS.APIARY_ID)

  const apiaryEventDraft = useAppSelector(
    (state) => state.apiaryEventsSlice.draft,
  )

  const baselineApiaryEventDraft = {
    ...initialApiaryEventState,
    apiary: apiaryId ? apiaryId : apiaryEventDraft.apiary,
  }

  const hasChanges =
    apiaryEventDraft && !isEqual(baselineApiaryEventDraft, apiaryEventDraft)

  useLayoutEffect(() => {
    if (apiaryId) {
      dispatch(setDraftApiaryEventApiary(apiaryId))
    } else dispatch(resetApiaryEventDraft({}))
  }, [apiaryId, dispatch])

  return { apiaryEventDraft, hasChanges }
}

export default useInitializeApiaryEventDraft
