import { skipToken } from "@reduxjs/toolkit/query"
import { useLayoutEffect } from "react"
import { useGetApiaryEventByIdQuery } from "@/features/events/apiary-events/api/apiary-events-api.ts"
import { useAppDispatch, useAppSelector } from "@/redux/hooks.ts"
import { setApiaryEventsDraft } from "@/redux/slices/apiary-events-slice.ts"

const useGetDraftApiaryEvent = (apiaryEventId: string | undefined) => {
  const dispatch = useAppDispatch()
  const { data, isLoading } = useGetApiaryEventByIdQuery(
    apiaryEventId || skipToken,
  )

  const draftEvent = useAppSelector((state) => state.apiaryEventsSlice.draft)

  useLayoutEffect(() => {
    if (data) {
      dispatch(setApiaryEventsDraft(data))
    }
  }, [data, dispatch])

  return { draftEvent, data, isLoading }
}

export default useGetDraftApiaryEvent
