import React, { type ChangeEvent, useEffect, useMemo, useCallback } from "react"
import { useTranslation } from "react-i18next"
import WeightScaleIcon from "@/assets/icons/misc/weight-scale-icon.tsx"
import InputFieldWrapperWithIcon from "@/components/text-card-wrapper-with-icon/input-field-wrapper-with-icon.tsx"
import useCheckHiveEventType from "@/features/events/hive-events/hooks/use-check-hive-event-type.ts"
import {
  HIVE_EVENT_TYPES_ENUM,
  HIVE_MEASUREMENT_TYPES_ENUM,
  HIVE_MEASUREMENT_UNITS_ENUM,
} from "@/features/events/hive-events/types/hive-events-constants.ts"
import { useAppDispatch } from "@/redux/hooks.ts"
import {
  type HiveEventDraft,
  setHiveDraftEventSubDataMeasurementType,
  setHiveDraftEventSubDataMeasurementUnit,
  setHiveDraftEventSubDataMeasurementValue,
} from "@/redux/slices/hive-events-draft-slice.ts"

interface Props {
  draftEvent: HiveEventDraft
}

export const HiveEventMeasurementEditor: React.FC<Props> = ({ draftEvent }) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const checkEventType = useCheckHiveEventType()

  const isColonyStrength = useMemo(
    () =>
      checkEventType(
        draftEvent.type,
        HIVE_EVENT_TYPES_ENUM.ColonyStrengthAssessment,
      ),
    [draftEvent.type, checkEventType],
  )

  const isHiveWeight = useMemo(
    () =>
      checkEventType(draftEvent.type, HIVE_EVENT_TYPES_ENUM.WeightMeasurement),
    [draftEvent.type, checkEventType],
  )

  const isBroodFramesCount = useMemo(
    () =>
      checkEventType(draftEvent.type, HIVE_EVENT_TYPES_ENUM.BroodFramesCount),
    [draftEvent.type, checkEventType],
  )

  const isHoneyFramesCount = useMemo(
    () =>
      checkEventType(draftEvent.type, HIVE_EVENT_TYPES_ENUM.HoneyFramesCount),
    [draftEvent.type, checkEventType],
  )

  useEffect(() => {
    if (isColonyStrength) {
      dispatch(
        setHiveDraftEventSubDataMeasurementType(
          HIVE_MEASUREMENT_TYPES_ENUM.COLONY_STRENGTH,
        ),
      )
      dispatch(
        setHiveDraftEventSubDataMeasurementUnit(
          HIVE_MEASUREMENT_UNITS_ENUM.COUNT,
        ),
      )
    }

    if (isHiveWeight) {
      dispatch(
        setHiveDraftEventSubDataMeasurementType(
          HIVE_MEASUREMENT_TYPES_ENUM.WEIGHT,
        ),
      )
      dispatch(
        setHiveDraftEventSubDataMeasurementUnit(
          HIVE_MEASUREMENT_UNITS_ENUM.GRAMS,
        ),
      )
    }

    if (isBroodFramesCount) {
      dispatch(
        setHiveDraftEventSubDataMeasurementType(
          HIVE_MEASUREMENT_TYPES_ENUM.BROOD_FRAMES,
        ),
      )
      dispatch(
        setHiveDraftEventSubDataMeasurementUnit(
          HIVE_MEASUREMENT_UNITS_ENUM.COUNT,
        ),
      )
    }

    if (isHoneyFramesCount) {
      dispatch(
        setHiveDraftEventSubDataMeasurementType(
          HIVE_MEASUREMENT_TYPES_ENUM.HONEY_FRAMES,
        ),
      )
      dispatch(
        setHiveDraftEventSubDataMeasurementUnit(
          HIVE_MEASUREMENT_UNITS_ENUM.COUNT,
        ),
      )
    }
  }, [
    dispatch,
    isColonyStrength,
    isHiveWeight,
    isBroodFramesCount,
    isHoneyFramesCount,
  ])

  const setDraftEventValue = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value
      if (/^\d*$/.test(value)) {
        if (+value < 0) return
        dispatch(setHiveDraftEventSubDataMeasurementValue(value))
      }
    },
    [dispatch],
  )

  const getDetails = () => {
    if (isColonyStrength) {
      return t("bees")
    }

    if (isHiveWeight) {
      return t("grams")
    }

    if (isBroodFramesCount || isHoneyFramesCount) {
      return t("pieces")
    }

    return
  }

  return (
    <>
      <div className="mt-2 text-[14px] text-neutral-400">
        {t("specificEventDetails")}
      </div>
      <InputFieldWrapperWithIcon
        icon={<WeightScaleIcon className={"w-[30px] text-white"} />}
        align="row"
        name={t("value")}
        maxLength={14}
        min={0}
        inputMode="numeric"
        isDisabled={false}
        value={draftEvent.event_data?.value ?? ""}
        isRequired={!draftEvent.event_data?.value}
        onChange={setDraftEventValue}
        details={getDetails()}
      />
    </>
  )
}
