import { createSlice, type PayloadAction } from "@reduxjs/toolkit"
import {
  type HiveInput,
  hiveInputInitialState,
  type HiveType,
} from "@/features/bees/types/hives.ts"
import { logout } from "@/redux/slices/auth-slice"

type HiveDraftSlice = {
  drafts: Record<string, HiveInput>
}

const initialState: HiveDraftSlice = {
  drafts: {},
}

const hiveDraftSlice = createSlice({
  name: "hiveDraft",
  initialState,
  reducers: {
    setHiveDraft: (
      state,
      action: PayloadAction<{ id: string; draft: HiveInput }>,
    ) => {
      state.drafts[action.payload.id] = action.payload.draft
    },
    setDraftHiveApiary: (
      state,
      action: PayloadAction<{ id: string; apiary: string }>,
    ) => {
      state.drafts[action.payload.id].apiary = action.payload.apiary
    },
    setDraftHiveCode: (
      state,
      action: PayloadAction<{ id: string; code: string }>,
    ) => {
      state.drafts[action.payload.id].code = action.payload.code
    },
    setDraftHiveType: (
      state,
      action: PayloadAction<{ id: string; hive_type: HiveType }>,
    ) => {
      state.drafts[action.payload.id].hive_type = action.payload.hive_type
    },
    setDraftHiveManufacturer: (
      state,
      action: PayloadAction<{ id: string; hive_manufacturer: string }>,
    ) => {
      state.drafts[action.payload.id].hive_manufacturer =
        action.payload.hive_manufacturer
    },
    setDraftEmptyFrames: (
      state,
      action: PayloadAction<{ id: string; number_of_empty_frames: string }>,
    ) => {
      state.drafts[action.payload.id].number_of_empty_frames =
        action.payload.number_of_empty_frames
    },
    setDraftHoneyFrames: (
      state,
      action: PayloadAction<{ id: string; number_of_honey_frames: string }>,
    ) => {
      state.drafts[action.payload.id].number_of_honey_frames =
        action.payload.number_of_honey_frames
    },
    setDraftBroodFrames: (
      state,
      action: PayloadAction<{ id: string; number_of_brood_frames: string }>,
    ) => {
      state.drafts[action.payload.id].number_of_brood_frames =
        action.payload.number_of_brood_frames
    },
    setDraftHiveDescription: (
      state,
      action: PayloadAction<{ id: string; description: string }>,
    ) => {
      state.drafts[action.payload.id].description = action.payload.description
    },
    setDraftHivePicture: (
      state,
      action: PayloadAction<{ id: string; picture: string | null }>,
    ) => {
      state.drafts[action.payload.id].picture = action.payload.picture
    },
    setDraftBeeCount: (
      state,
      action: PayloadAction<{ id: string; bee_count: string }>,
    ) => {
      state.drafts[action.payload.id].bee_count = action.payload.bee_count
    },
    setDraftHiveCurrentPosition: (
      state,
      action: PayloadAction<{ id: string; current_position: string }>,
    ) => {
      state.drafts[action.payload.id].current_position =
        action.payload.current_position
    },
    setDraftHiveSuperCount: (
      state,
      action: PayloadAction<{ id: string; super_count: string }>,
    ) => {
      state.drafts[action.payload.id].super_count = action.payload.super_count
    },
    setDraftHiveBroodBoxCount: (
      state,
      action: PayloadAction<{ id: string; brood_box_count: string }>,
    ) => {
      state.drafts[action.payload.id].brood_box_count =
        action.payload.brood_box_count
    },
    setDraftHiveLastWeight: (
      state,
      action: PayloadAction<{ id: string; last_weight: string }>,
    ) => {
      state.drafts[action.payload.id].last_weight = action.payload.last_weight
    },
    resetHiveDraft: (
      state,
      action: PayloadAction<{ id: string; draft?: Partial<HiveInput> }>,
    ) => {
      state.drafts[action.payload.id] = {
        ...hiveInputInitialState,
        ...action.payload.draft,
      }
    },
    deleteHiveDraft: (state, action: PayloadAction<string>) => {
      delete state.drafts[action.payload]
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout.fulfilled, () => initialState)
  },
})

export default hiveDraftSlice.reducer

export const {
  setHiveDraft,
  setDraftHiveApiary,
  setDraftHiveCode,
  setDraftHiveType,
  setDraftHiveManufacturer,
  setDraftEmptyFrames,
  setDraftHoneyFrames,
  setDraftBroodFrames,
  setDraftHiveDescription,
  setDraftHivePicture,
  setDraftBeeCount,
  resetHiveDraft,
  deleteHiveDraft,
  setDraftHiveCurrentPosition,
  setDraftHiveSuperCount,
  setDraftHiveBroodBoxCount,
  setDraftHiveLastWeight,
} = hiveDraftSlice.actions
