import omitBy from "lodash/omitBy"
import { apiSlice, TagType } from "@/features/api/apiSlice.ts"
import { type ApiaryEvent } from "@/features/events/apiary-events/types/apiary-event.ts"
import { isEmptyValue } from "@/features/events/common/utils/utils.ts"

export const APIARY_EVENTS_API_BASE_URL = "/apiary-events/"

interface GetApiaryEventsParams {
  apiary_id?: string
  date_from?: string
  date_to?: string
  event_type?: string
}

export type ApiaryEventInput = Omit<
  ApiaryEvent,
  "id" | "user" | "created_at" | "updated_at"
>

export const apiaryEventsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllApiaryEvents: builder.query<
      ApiaryEvent[],
      Partial<GetApiaryEventsParams>
    >({
      query: (params) => ({
        url: APIARY_EVENTS_API_BASE_URL,
        params: {
          apiary_id: params.apiary_id,
          date_from: params.date_from,
          date_to: params.date_to,
          event_type: params.event_type,
        },
      }),
      providesTags: [{ type: TagType.ApiaryEvents, id: "LIST" }],
    }),
    getApiaryEventById: builder.query<ApiaryEvent, string>({
      query: (id) => `${APIARY_EVENTS_API_BASE_URL}${id}/`,
      providesTags: (_result, _error, id) => [
        { type: TagType.ApiaryEvents, id },
      ],
    }),
    createApiaryEvent: builder.mutation<ApiaryEvent, ApiaryEventInput>({
      query: (body) => {
        const { event_data, ...rest } = body
        const modifiedApiaryEvent = {
          ...rest,
          event_sub_data: event_data,
        }

        return {
          url: APIARY_EVENTS_API_BASE_URL,
          method: "POST",
          body: modifiedApiaryEvent,
        }
      },
      invalidatesTags: (_result, _error, body) => [
        { type: TagType.ApiaryEvents, id: "LIST" },
        { type: TagType.Apiaries, id: body.apiary },
      ],
    }),
    updateApiaryEvent: builder.mutation<
      ApiaryEvent,
      ApiaryEventInput & { id: string }
    >({
      query: (patch) => {
        const { event_data, ...rest } = patch
        // @ts-ignore
        const { event, ...event_sub_data } = event_data || {}

        const filteredEventSubData = omitBy(event_sub_data, isEmptyValue)

        const modifiedPatch = {
          ...rest,
          event_sub_data: Object.keys(filteredEventSubData).length
            ? filteredEventSubData
            : undefined,
        }

        return {
          url: `${APIARY_EVENTS_API_BASE_URL}${patch.id}/`,
          method: "PATCH",
          body: modifiedPatch,
        }
      },
      invalidatesTags: (_result, _error, body) => [
        { type: TagType.ApiaryEvents, id: body.id },
        { type: TagType.ApiaryEvents, id: "LIST" },
      ],
    }),
    deleteApiaryEvent: builder.mutation<void, string>({
      query: (id) => ({
        url: `${APIARY_EVENTS_API_BASE_URL}${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: (_result, _error, id) => [
        { type: TagType.ApiaryEvents, id },
        { type: TagType.ApiaryEvents, id: "LIST" },
      ],
    }),
  }),
})

export const {
  useGetAllApiaryEventsQuery,
  useCreateApiaryEventMutation,
  useGetApiaryEventByIdQuery,
  useDeleteApiaryEventMutation,
  useUpdateApiaryEventMutation,
} = apiaryEventsApi
