import type GeneralSvgProps from "@/utils/types/sgv/general-svg-props.ts"

export const SupperBoxIcon: React.FC<GeneralSvgProps> = (props) => {
  return (
    <svg
      {...props}
      viewBox="-3.2 -3.2 38.40 38.40"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      fill="#ffffff"
      stroke="#ffffff"
    >
      <g id="SVGRepo_bgCarrier" stroke-width="0">
        <rect
          x="-3.2"
          y="-3.2"
          width="38.40"
          height="38.40"
          rx="7.68"
          fill="#8b7eec"
          strokeWidth="0"
        ></rect>
      </g>
      <g
        id="SVGRepo_tracerCarrier"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        <g id="icomoon-ignore"></g>
        <path
          d="M23.505 6.937h-14.969l-5.864 6.397v11.729h26.656v-11.729l-5.823-6.397zM9.005 8.003h14.029l4.852 5.331h-8.155c0 1.997-1.735 3.61-3.732 3.61s-3.732-1.613-3.732-3.61h-8.149l4.887-5.331zM28.262 23.997h-24.524v-9.596h7.596c0.51 2.041 2.441 3.609 4.666 3.609s4.156-1.569 4.666-3.61h7.596v9.596z"
          fill="#000000"
        ></path>
      </g>
    </svg>
  )
}
