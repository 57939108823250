import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import GenericTypeSelectTrigger from "@/components/drawer-triggers/generic-type-select-trigger.tsx"
import {
  Drawer,
  DrawerContent,
  DrawerTrigger,
} from "@/components/shadcn/drawer.tsx"
import GenericTypeDrawerContent from "@/features/events/animal-events/components/events-drawer-content/generic-type-drawer-content.tsx"
import { type Medication } from "@/features/events/common/types/common-events-types.ts"

interface GenericTypeDrawerProps {
  selectedValue: string | undefined
  fallbackText: string
  onEventSelect: (eventType: string | undefined) => void
  medications: Record<string, Medication>
}

export const HiveMedicationTypeDrawer: React.FC<GenericTypeDrawerProps> = ({
  selectedValue,
  onEventSelect,
  medications,
  fallbackText,
}) => {
  const { t } = useTranslation()
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const currentMedicationType = selectedValue
    ? medications[selectedValue]
    : undefined

  const handleEventTypeSelect = (eventType: string | undefined) => {
    onEventSelect(eventType)
    setIsDrawerOpen(false)
  }

  return (
    <Drawer open={isDrawerOpen} onOpenChange={setIsDrawerOpen}>
      <DrawerTrigger asChild>
        <GenericTypeSelectTrigger
          currentValue={currentMedicationType}
          fallbackText={fallbackText}
          isRequired={true}
        />
      </DrawerTrigger>
      <DrawerContent
        style={{
          height: "85%",
          width: "100%",
        }}
      >
        <GenericTypeDrawerContent
          selectedValue={selectedValue}
          onValueSelect={handleEventTypeSelect}
          values={medications}
          placeholderText={t(fallbackText)}
        />
      </DrawerContent>
    </Drawer>
  )
}
