import isEmpty from "lodash/isEmpty"
import React from "react"
import { SelectPill } from "@/components/select-pill/select-pill.tsx"

interface CustomTriggerProps {
  currentValue: string | undefined
  onClick?: () => void
  displayText: () => string
}

const GenericButtonTrigger = React.forwardRef<
  HTMLButtonElement,
  CustomTriggerProps
>(({ currentValue, onClick, displayText }, ref) => {
  const isSelected = () => {
    return !isEmpty(currentValue)
  }

  return (
    <SelectPill isActive={isSelected()} ref={ref} onClick={onClick}>
      {displayText()}
    </SelectPill>
  )
})

GenericButtonTrigger.displayName = "GenericButtonTrigger"

export default GenericButtonTrigger
