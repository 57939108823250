import React from "react"
import styles from "./events-table.module.css"
import { AnimalEventsTableRow } from "@/features/events/animal-events/components/animal-events-table-row/animal-events-table-row.tsx"
import { type GenericAnimalEvent } from "@/features/events/animal-events/types/animal-events.ts"
import { ApiaryEventsTableRow } from "@/features/events/apiary-events/components/apiary-events-table-row/apiary-events-table-row.tsx"
import { type ApiaryEvent } from "@/features/events/apiary-events/types/apiary-event.ts"
import { HiveEventsTableRow } from "@/features/events/hive-events/components/hive-events-widget/hive-event-table-row.tsx"
import { type HiveEvent } from "@/features/events/hive-events/types/hive-event.ts"

type AllEventTypes = GenericAnimalEvent[] | ApiaryEvent[] | HiveEvent[]

interface EventTableProps {
  events: AllEventTypes
}

const TableHeader = () => {
  return (
    <thead className={styles.tableHeader}>
      <tr>
        <th className={styles.tableHeaderCell}>Tip</th>
        <th className={styles.tableHeaderCell}>Data</th>
      </tr>
    </thead>
  )
}

export const EventTable: React.FC<EventTableProps> = ({ events }) => {
  const isApiaryEvent = (event: {}) => {
    return event.hasOwnProperty("apiary")
  }

  const isHiveEvent = (event: {}) => {
    return event.hasOwnProperty("hive")
  }

  const isAnimalEvent = (event: {}) => {
    return event.hasOwnProperty("animal")
  }

  return (
    <table className={styles.tableContainer}>
      <TableHeader />
      <tbody>
        {events.map((event, index) => (
          <>
            {isApiaryEvent(event) && (
              <ApiaryEventsTableRow event={event as ApiaryEvent} key={index} />
            )}
            {isHiveEvent(event) && (
              <HiveEventsTableRow event={event as HiveEvent} key={index} />
            )}
            {isAnimalEvent(event) && (
              <AnimalEventsTableRow
                event={event as GenericAnimalEvent}
                key={index}
              />
            )}
          </>
        ))}
      </tbody>
    </table>
  )
}
