import React from "react"
import { cn } from "@/utils/styles.ts"

interface DrawerContentWrapperProps {
  children: React.ReactNode
  className?: string
}

const DrawerContentWrapper: React.FC<DrawerContentWrapperProps> = ({
  children,
  className,
}) => {
  return (
    <div className={cn("h-full overflow-y-auto overflow-x-hidden", className)}>
      {children}
    </div>
  )
}

export default DrawerContentWrapper
