import { useTranslation } from "react-i18next"
import styles from "./generic-drawer-selection-row.module.css"
import PlaceholderIcon from "@/assets/icons/misc/placeholder-icon.tsx"
import GenericPictureContained from "@/components/generic-picture/generic-picture-contained.tsx"
import useLongPress from "@/hooks/use-long-press.ts"

interface GenericDrawerSelectionRowInterface {
  id: string
  name: string
  picture?: string | null
}

interface GenericDrawerSelectionRowProps<
  T extends GenericDrawerSelectionRowInterface,
> {
  isSelected: boolean
  onClick: () => void
  onLongClick: () => void
  entity: T
  backGroundColor?: string
  style?: React.CSSProperties
}

const GenericDrawerSelectionRow = <
  T extends GenericDrawerSelectionRowInterface,
>({
  entity,
  isSelected,
  onClick,
  onLongClick,
  backGroundColor,
  style,
}: GenericDrawerSelectionRowProps<T>) => {
  const { t } = useTranslation()
  const longPressHandlers = useLongPress(onLongClick)

  return (
    <div
      key={entity.picture}
      {...longPressHandlers}
      data-selected={isSelected}
      className={styles.titleFlexCategory}
      onClick={onClick}
    >
      {entity.picture ? (
        <GenericPictureContained
          src={entity.picture}
          alt=""
          rerenderOnLoad={true}
          size={30}
          borderRadius={8}
          shadow={true}
          style={{
            backgroundColor: backGroundColor,
            ...style,
          }}
        />
      ) : (
        <PlaceholderIcon />
      )}
      <div className={styles.title}>{t(entity.name)}</div>
    </div>
  )
}

export default GenericDrawerSelectionRow
