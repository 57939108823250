import type GeneralSvgProps from "@/utils/types/sgv/general-svg-props.ts"

export const FloraTypeIcon: React.FC<GeneralSvgProps> = (props) => {
  return (
    <svg
      {...props}
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      height={20}
      width={20}
    >
      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        <path
          className="a"
          d="M16.9994,11.8686C17.9538,7.9067,21.2849,3.5,24.0022,3.5c2.7134,0,6.0389,4.3942,6.9986,8.3516"
        ></path>
        <circle className="a" cx="24.0022" cy="24" r="4.5425"></circle>
        <path
          className="a"
          d="M31.0049,36.1314C30.0505,40.0932,26.7194,44.5,24.0021,44.5c-2.7134,0-6.0388-4.3941-6.9986-8.3515"
        ></path>
        <path
          className="a"
          d="M16.9994,11.8686C17.9538,7.9067,21.2849,3.5,24.0022,3.5c2.7134,0,6.0389,4.3942,6.9986,8.3516"
        ></path>
        <path
          className="a"
          d="M12.74,11.3219c-2.87.04-5.5894.8657-6.4914,2.4281C4.6444,16.5276,7.93,23.1154,11.6549,25.2658a13.5331,13.5331,0,0,0,8.06,1.21A13.5309,13.5309,0,0,0,16.7327,34.06c0,4.3007,4.0621,10.44,7.2694,10.44s7.27-6.14,7.27-10.44a13.531,13.531,0,0,0-2.9806-7.5817,13.5358,13.5358,0,0,0,8.0547-1.21c3.7245-2.15,7.0086-8.7381,5.405-11.5158s-8.9518-3.2258-12.6762-1.0754A13.5326,13.5326,0,0,0,24,19.0459a13.5336,13.5336,0,0,0-5.076-6.3714A12.5652,12.5652,0,0,0,12.74,11.3219Z"
        ></path>
        <path
          className="a"
          d="M38.01,24.0011c2.9538,2.8074,5.1047,7.8956,3.746,10.2489-1.3567,2.35-6.8249,3.0327-10.7321,1.8852"
        ></path>
        <path
          className="a"
          d="M16.9932,36.1328c-3.9082,1.1544-9.39.473-10.7488-1.88-1.3567-2.35.786-7.4269,3.7334-10.2367"
        ></path>
      </g>
    </svg>
  )
}
