import React from "react"
import { useTranslation } from "react-i18next"
import ChooseSomethingIcon from "@/assets/icons/misc/choose-something-icon.tsx"
import RequiredIndicator from "@/features/farm/components/required-indicator/required-indicator.tsx"

interface GenericTriggerProps<T> {
  currentValue: T | undefined
  onClick?: () => void
  displayText: (
    currentValue: T | undefined,
    t: (key: string) => string,
  ) => string
  displayIcon?: (currentValue: T | undefined) => JSX.Element | null
  isRequired?: boolean
}

const GenericSelectTrigger = React.forwardRef<
  HTMLDivElement,
  GenericTriggerProps<any>
>(
  (
    { onClick, currentValue, displayText, displayIcon, isRequired = true },
    ref,
  ) => {
    const { t } = useTranslation()

    return (
      <div ref={ref} onClick={onClick}>
        {isRequired && currentValue === undefined && <RequiredIndicator />}
        <div className={"grid grid-cols-[30px_auto] items-center gap-[10px]"}>
          {currentValue && displayIcon ? (
            displayIcon(currentValue)
          ) : (
            <ChooseSomethingIcon />
          )}
          <div className={"truncate text-[14px]"}>
            {displayText(currentValue, t)}
          </div>
        </div>
      </div>
    )
  },
)

GenericSelectTrigger.displayName = "GenericSelectTrigger"

export default GenericSelectTrigger
