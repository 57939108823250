import React, { type CSSProperties } from "react"
import {
  type DateRange,
  type InternalModifiersElement,
  type StyledElement,
} from "react-day-picker"
import styles from "./generic-date-picker.module.css"
import Calendar from "@/components/shadcn/calendar/calendar.tsx"

interface GenericCalendarProps {
  receivedDate: DateRange | undefined
  setIsOpened?: (value: boolean) => void
  onChange: (date: DateRange | undefined) => void
  withFooter?: boolean
  datePickerStyles?:
    | Partial<Omit<StyledElement<CSSProperties>, InternalModifiersElement>>
    | undefined
}

const GenericCalendarRange: React.FC<GenericCalendarProps> = ({
  receivedDate,
  setIsOpened,
  onChange,
  withFooter = true,
  datePickerStyles,
}) => {
  const handleSelect = (date: DateRange | undefined) => {
    onChange(date)
  }

  return (
    <div className={styles.calendarWrapper}>
      <Calendar
        mode={"range"}
        selected={receivedDate}
        defaultMonth={receivedDate?.from}
        onSelect={handleSelect}
        style={{
          margin: "0px",
        }}
        styles={datePickerStyles}
        captionLayout={"dropdown-buttons"}
        fromDate={new Date(2020, 0, 1)}
        toDate={new Date(2100, 0, 1)}
      />
      {withFooter && setIsOpened && (
        <div className={styles.calendarFooter}>
          <button
            className={styles.calendarButton}
            onClick={() => setIsOpened(false)}
          >
            Ok
          </button>
        </div>
      )}
    </div>
  )
}

export default GenericCalendarRange
