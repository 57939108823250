import React from "react"
import { type DateInterval } from "react-day-picker"
import styles from "./generic-date-picker.module.css"
import Calendar from "@/components/shadcn/calendar/calendar.tsx"

interface GenericCalendarProps {
  receivedDate: Date | undefined
  setIsOpened: (value: boolean) => void
  onChange: (date: Date | undefined) => void
  withFooter?: boolean
  dateInterval?: DateInterval
}

const GenericCalendar: React.FC<GenericCalendarProps> = ({
  receivedDate,
  setIsOpened,
  onChange,
  withFooter = true,
  dateInterval,
}) => {
  /**
   * if the footer is not present, hence no ok button, the calendar will be closed on selection
   *
   * @param date
   */
  const handleSelect = (date: Date | undefined) => {
    onChange(date)
    !withFooter && setIsOpened(false)
  }

  return (
    <div className={styles.calendarWrapper}>
      <Calendar
        mode={"single"}
        selected={receivedDate}
        defaultMonth={receivedDate}
        onSelect={handleSelect}
        disabled={
          dateInterval
            ? {
                after: dateInterval.before,
                before: dateInterval.after,
              }
            : undefined
        }
        style={{
          margin: "0px",
        }}
      />
      {withFooter && (
        <div className={styles.calendarFooter}>
          <button
            className={styles.calendarButton}
            onClick={() => setIsOpened(false)}
          >
            Ok
          </button>
        </div>
      )}
    </div>
  )
}

export default GenericCalendar
