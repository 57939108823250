import { format } from "date-fns"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { useGetAllAnimalEventTypesQuery } from "@/features/events/animal-events/api/event-types-api.ts"
import { type GenericAnimalEvent } from "@/features/events/animal-events/types/animal-events.ts"
import styles from "@/features/events/common/components/events-table/events-table.module.css"
import { type BaseEvent } from "@/features/events/common/types/common-events-types.ts"
import { ANIMAL_EVENT_SCREEN_PATH } from "@/utils/constants/routes.ts"

interface AnimalEventTableRowProps<T extends BaseEvent> {
  event: T
  eventTypes: Record<string, { name: string }>
  routePath: string
}

export const AnimalEventTableRow = <T extends BaseEvent>({
  event,
  eventTypes,
  routePath,
}: AnimalEventTableRowProps<T>) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const eventTypeName = eventTypes?.[event.type]?.name ?? ""
  const navigateToEvent = () => {
    navigate(`${routePath}/${event.id}`)
  }
  return (
    <tr className={styles.tableRow} onClick={navigateToEvent}>
      {eventTypeName && (
        <>
          <td className={styles.tableCell}>{t(eventTypeName)}</td>
          <td className={styles.dateCell}>
            {format(new Date(event.date), "dd.MM.yyyy")}
          </td>
        </>
      )}
    </tr>
  )
}

export const AnimalEventsTableRow = ({
  event,
}: {
  event: GenericAnimalEvent
}) => {
  const { data: eventTypes } = useGetAllAnimalEventTypesQuery()

  return (
    <AnimalEventTableRow
      event={event as BaseEvent}
      eventTypes={eventTypes ?? {}}
      routePath={ANIMAL_EVENT_SCREEN_PATH}
    />
  )
}
