import React from "react"
import { useTranslation } from "react-i18next"
import { DrawerClose } from "@/components/shadcn/drawer.tsx"
import { cn } from "@/utils/styles.ts"
import { type Option } from "@/utils/types/misc-types.ts"

interface Props {
  option: Option
  isSelected: boolean
  onSelectionChange: (value: string) => void
  selectionMode?: "single" | "multiple"
}

export const SelectionRow: React.FC<Props> = ({
  option,
  isSelected,
  onSelectionChange,
  selectionMode = "single",
}) => {
  const Wrapper = selectionMode == "single" ? DrawerClose : "div"
  const { t } = useTranslation()
  return (
    <Wrapper asChild={selectionMode === "single"}>
      <div
        className={cn(
          "flex h-10 cursor-pointer items-center gap-2 px-4",
          isSelected ? "bg-brown-50" : "",
        )}
        onClick={() => onSelectionChange(option.value)}
      >
        <div className="grid h-5 w-5 place-content-center rounded-[50%] border border-brown-700">
          {isSelected && (
            <div className="h-3 w-3 rounded-[50%] border border-brown-500 bg-brown-500" />
          )}
        </div>
        <div className="text-body-xs text-neutral-700">{t(option.label)}</div>
      </div>
    </Wrapper>
  )
}
