import useCheckHiveEventType from "@/features/events/hive-events/hooks/use-check-hive-event-type.ts"
import {
  HIVE_EVENT_TYPES_ENUM,
  HIVE_MOVEMENT_EVENTS_ENUM,
} from "@/features/events/hive-events/types/hive-events-constants.ts"

export interface HiveEventDataTypeObject {
  isMovementEvent: boolean
  isMeasurementEvent: boolean
  isTreatmentEvent: boolean
  isEquipmentEvent: boolean
  isInspectionEvent: boolean
  isApiaryMovementEvent: boolean
}

export const useGetHiveEventDataTypeObject = (
  eventDraftId: string | undefined,
): HiveEventDataTypeObject => {
  const checkEventType = useCheckHiveEventType()

  const isMovementEvent = checkEventType(
    eventDraftId,
    HIVE_EVENT_TYPES_ENUM.HiveMovement,
  )

  const isMeasurementEvent = checkEventType(
    eventDraftId,
    HIVE_EVENT_TYPES_ENUM.HiveMeasurement,
  )

  const isTreatmentEvent = checkEventType(
    eventDraftId,
    HIVE_EVENT_TYPES_ENUM.HiveTreatment,
  )

  const isEquipmentEvent = checkEventType(
    eventDraftId,
    HIVE_EVENT_TYPES_ENUM.HiveEquipment,
  )

  const isInspectionEvent = checkEventType(
    eventDraftId,
    HIVE_EVENT_TYPES_ENUM.HiveInspection,
  )

  const isApiaryMovementEvent = checkEventType(
    eventDraftId,
    HIVE_MOVEMENT_EVENTS_ENUM.TransferToOtherApiary,
  )

  return {
    isMovementEvent,
    isMeasurementEvent,
    isTreatmentEvent,
    isEquipmentEvent,
    isInspectionEvent,
    isApiaryMovementEvent,
  }
}
