import { skipToken } from "@reduxjs/toolkit/query"
import { useLayoutEffect } from "react"
import { useGetHiveEventByIdQuery } from "@/features/events/hive-events/api/hive-events-api.ts"
import { useAppDispatch, useAppSelector } from "@/redux/hooks.ts"
import { setHiveEventsDraft } from "@/redux/slices/hive-events-draft-slice.ts"

const useGetDraftHiveEvent = (hiveEventId: string | undefined) => {
  const dispatch = useAppDispatch()
  const { data, isLoading } = useGetHiveEventByIdQuery(hiveEventId || skipToken)

  const draftEvent = useAppSelector((state) => state.hiveEventsDraftSlice.draft)

  useLayoutEffect(() => {
    if (data) {
      dispatch(setHiveEventsDraft(data))
    }
  }, [data, dispatch])

  return { draftEvent, data, isLoading }
}

export default useGetDraftHiveEvent
