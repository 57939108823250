import React from "react"
import DrawerContentWrapper from "@/components/drawer-content-wrapper/drawer-content-wrapper.tsx"
import GenericDrawerSelectionRow from "@/components/generic-drawer-selection-row/generic-drawer-selection-row.tsx"
import GenericTypeDrawerHeader from "@/components/generic-type-drawer/generic-type-drawer-header/generic-type-drawer-header.tsx"
import { type GenericType } from "@/features/events/animal-events/types/animal-events.ts"

interface GenericTypeDrawerContentProps {
  selectedValue: string | undefined
  onValueSelect: (eventType: string | undefined) => void
  placeholderText: string
  values: Record<string, GenericType>
  onViewChange?: (entityId: string) => void
}

// Shall this still show the Tick icon on the right side of the drawer header?
// considering that the selection is made on the row itself
const GenericTypeDrawerContent: React.FC<GenericTypeDrawerContentProps> = ({
  selectedValue,
  onValueSelect,
  values,
  placeholderText,
  onViewChange,
}) => {
  const handleEventTypeSelect = (eventType: string | undefined) => {
    onValueSelect(eventType)
  }

  const handleOnLongClick = (value: GenericType) => {
    onViewChange && onViewChange(value.id)
  }

  return (
    <>
      <GenericTypeDrawerHeader title={placeholderText} onSaveClick={() => {}} />
      <DrawerContentWrapper>
        {Object.values(values).map((value) => (
          <GenericDrawerSelectionRow
            isSelected={selectedValue === value.id}
            onClick={() => {
              handleEventTypeSelect(value.id)
            }}
            onLongClick={() => handleOnLongClick(value)}
            entity={value}
            key={value.id}
          />
        ))}
      </DrawerContentWrapper>
    </>
  )
}

export default GenericTypeDrawerContent
