import { type HiveResponse } from "@/features/bees/types/hives.ts"

export interface HiveFilterSelection {
  searchQuery: string
  apiary: string
  type: string
}

export enum HIVES_FILTER_ENUM {
  SEARCH_QUERY = "",
  APIARY = "apiary",
  TYPE = "type",
  ALL_APIARIES = "allApiaries",
  ALL_HIVES = "allHives",
  ALL_HIVE_TYPES = "allHiveTypes",
}

export const filterHives = (
  hives: HiveResponse[],
  selections: HiveFilterSelection,
) => {
  return hives.filter((hive) => {
    const matchesSearch = Object.entries(hive).some(
      ([key, value]) =>
        key !== "id" &&
        typeof value === "string" &&
        value.toLowerCase().includes(selections.searchQuery.toLowerCase()),
    )

    const matchesType =
      selections.type === HIVES_FILTER_ENUM.ALL_HIVE_TYPES ||
      hive.hive_type === selections.type

    const matchesApiary =
      selections.apiary === HIVES_FILTER_ENUM.ALL_APIARIES ||
      hive.apiary === selections.apiary

    return matchesApiary && matchesType && matchesSearch
  })
}
