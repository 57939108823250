import React, { type ChangeEvent, type ReactNode } from "react"
import styles from "./text-card-wrapper-with-icon.module.css"
import OverflowMenuItem from "@/components/task-card/overflow-menu-item.tsx"
import RequiredIndicator from "@/features/farm/components/required-indicator/required-indicator.tsx"
import { InputType } from "@/utils/enums/input-type-enum.ts"

interface TextCardProps {
  icon: ReactNode
  align: "row" | "column"
  name: string
  max?: number
  min?: number
  isDisabled: boolean
  isRequired?: boolean
  inputMode?:
    | "text"
    | "search"
    | "none"
    | "tel"
    | "url"
    | "email"
    | "numeric"
    | "decimal"
    | undefined
  value: any
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  maxLength?: number
  details?: string
}

const InputFieldWrapperWithIcon: React.FC<TextCardProps> = ({
  icon,
  align,
  name,
  isDisabled,
  value = "",
  onChange,
  isRequired = false,
  inputMode = "text",
  maxLength = 25,
  details,
  max,
  min,
}) => {
  return (
    <OverflowMenuItem isDisabled={isDisabled}>
      <div
        className={styles.textCardIconWrapper}
        style={{ flexDirection: align }}
      >
        <div className={styles.icon}>{icon}</div>
        <input
          name={name}
          alt={name}
          maxLength={maxLength}
          inputMode={inputMode}
          max={max}
          min={min}
          className={`${styles.textInput} ${isDisabled ? styles.inputDisabled : ""}`}
          type={inputMode === "numeric" ? InputType.NUMBER : InputType.TEXT}
          placeholder={name}
          value={value}
          onChange={(event: ChangeEvent<HTMLInputElement>) => onChange(event)}
          readOnly={isDisabled}
        />
        {details && <div className={styles.details}>{details}</div>}
      </div>
      {isRequired && <RequiredIndicator />}
    </OverflowMenuItem>
  )
}

export default InputFieldWrapperWithIcon
