import { format } from "date-fns"
import React, { useState } from "react"
import { type DateRange } from "react-day-picker"
import { useTranslation } from "react-i18next"
import DrawerContentWrapper from "@/components/drawer-content-wrapper/drawer-content-wrapper.tsx"
import GenericCalendarRange from "@/components/generic-date-picker/generic-calendar-range.tsx"
import { SelectPill } from "@/components/select-pill/select-pill.tsx"
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerTrigger,
} from "@/components/shadcn/drawer.tsx"
import { cn } from "@/utils/styles.ts"

interface Props {
  selectedTimeFrame: DateRange | undefined
  onSelectedTimeFrameChange: (dateRange: DateRange | undefined) => void
}

export const DateFilterDrawer: React.FC<Props> = ({
  selectedTimeFrame,
  onSelectedTimeFrameChange,
}) => {
  const { t } = useTranslation()

  const [timeFrame, setTimeFrame] = useState<DateRange | undefined>(
    selectedTimeFrame,
  )

  const handleTimeFrameChange = (dateRange: DateRange | undefined) => {
    setTimeFrame(dateRange)
  }

  const handleFilterReset = () => {
    setTimeFrame(undefined)
    onSelectedTimeFrameChange(undefined)
  }

  const handleAgeRangeSelection = () => {
    onSelectedTimeFrameChange(timeFrame)
  }

  const isButtonDisabled = !timeFrame?.from
  const showResultsButtonClassName = cn(
    "flex h-[50px] w-full items-center justify-center rounded-[8px] bg-brown-500 text-[14px] font-semibold text-white",
    isButtonDisabled && "bg-neutral-100 text-neutral-700",
  )
  const clearButtonClassName = cn(
    "flex h-[50px] w-full items-center justify-center rounded-[8px] bg-neutral-100 text-[14px] font-semibold text-neutral-700",
    isButtonDisabled && "opacity-0",
  )

  const getCalendarStyles = () => {
    const screenWidth = window.innerWidth
    const tableWidth = screenWidth - 34
    const cellSize = tableWidth / 7
    return {
      button: {
        height: cellSize,
        maxWidth: cellSize,
        width: cellSize,
        fontSize: "14px",
      },
      months: { width: "100%" },
      month: { width: "100%" },
      root: { width: tableWidth, border: "1px solid gray", borderRadius: 8 },
      table: { width: tableWidth, maxWidth: tableWidth },
      tbody: { width: tableWidth },
      caption: { fontSize: "16px", paddingLeft: "10px" },
    }
  }

  const triggerContent = () => {
    if (!timeFrame?.from && !timeFrame?.to) {
      return t("allTimes")
    } else if (timeFrame?.from && !timeFrame?.to) {
      return `${format(timeFrame.from, "dd-MM-yyyy")} - ${t("present")}`
    } else if (!timeFrame?.from && timeFrame?.to) {
      return `${t("present")} - ${format(timeFrame.to, "dd-MM-yyyy")}`
    } else if (timeFrame?.from && timeFrame?.to) {
      return `${format(timeFrame.from, "dd-MM-yyyy")} - ${format(
        timeFrame.to,
        "dd-MM-yyyy",
      )}`
    }
  }

  const isActive = timeFrame?.from || timeFrame?.to

  return (
    <Drawer>
      <DrawerTrigger>
        <SelectPill isActive={!!isActive}>{triggerContent()}</SelectPill>
      </DrawerTrigger>
      <DrawerContent className={"h-fit"}>
        <DrawerContentWrapper className="flex flex-col justify-between">
          <div>
            <GenericCalendarRange
              receivedDate={timeFrame}
              onChange={handleTimeFrameChange}
              withFooter={false}
              datePickerStyles={getCalendarStyles()}
            />
            <div
              className={
                "flex min-h-[40px] w-full items-center justify-between p-4"
              }
            >
              {
                <>
                  <div
                    className={
                      "grid w-[150px] place-content-center rounded-[8px] bg-gray-100 p-4"
                    }
                  >
                    {timeFrame && timeFrame?.from
                      ? format(timeFrame?.from, "dd-MM-yyyy")
                      : t("dateFrom")}
                  </div>
                  -
                  <div
                    className={
                      "grid w-[150px] place-content-center rounded-[8px] bg-gray-100 p-4"
                    }
                  >
                    {timeFrame && timeFrame.to
                      ? format(timeFrame?.to, "dd-MM-yyyy")
                      : t("dateTo")}
                  </div>
                </>
              }
            </div>
          </div>
          <div className={"flex flex-col gap-[10px] p-4"}>
            <DrawerClose>
              <button
                onClick={handleFilterReset}
                className={clearButtonClassName}
              >
                {t("resetFilter")}
              </button>
            </DrawerClose>
            <DrawerClose>
              <button
                disabled={isButtonDisabled}
                onClick={handleAgeRangeSelection}
                className={showResultsButtonClassName}
              >
                {t("showResults")}
              </button>
            </DrawerClose>
          </div>
        </DrawerContentWrapper>
      </DrawerContent>
    </Drawer>
  )
}
