import type GeneralSvgProps from "@/utils/types/sgv/general-svg-props.ts"

const ChooseSomethingIcon: React.FC<GeneralSvgProps> = (props) => {
  const size = props.size || 30
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="30" height="30" rx="4" fill="#EC8585" />
      <path
        d="M7 23H13V17H7V23ZM17 23H23V17H17V23ZM7 13H13V7H7V13ZM17 10C17 9.20435 17.3161 8.44129 17.8787 7.87868C18.4413 7.31607 19.2044 7 20 7C20.7956 7 21.5587 7.31607 22.1213 7.87868C22.6839 8.44129 23 9.20435 23 10C23 10.7956 22.6839 11.5587 22.1213 12.1213C21.5587 12.6839 20.7956 13 20 13C19.2044 13 18.4413 12.6839 17.8787 12.1213C17.3161 11.5587 17 10.7956 17 10Z"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}

export default ChooseSomethingIcon
