import AnimalEventsCard from "@/features/events/animal-events/components/animal-events-card/animal-events-card.tsx"
import { ApiaryEventCard } from "@/features/events/apiary-events/components/apiary-event-card/apiary-event-card.tsx"
import { HiveEventCard } from "@/features/events/hive-events/components/hive-event-card/hive-event-card.tsx"
import TransactionCard from "@/features/finance/components/transaction-card/transaction-card.tsx"
import { type ItemsArray } from "@/utils/helpers/time-grouping.ts"

interface GenericItemsSubGroupProps {
  items: ItemsArray
  title: string
  reverse?: boolean
}

const GenericItemsSubGroup: React.FC<GenericItemsSubGroupProps> = ({
  items,
  title,
  reverse = false,
}) => {
  const reverseItems = reverse ? items.reverse() : items

  return (
    <div className={"flex flex-col gap-[10px]"}>
      <div className={"text-[14px] text-neutral-700"}>{title}</div>
      {reverseItems.map((item, key) => {
        if ("animal" in item) {
          return <AnimalEventsCard animalEvent={item} key={key} />
        }
        if ("currency" in item) {
          return <TransactionCard transaction={item} key={key} />
        }
        if ("apiary" in item) {
          return <ApiaryEventCard event={item} key={key} />
        }
        if ("hive" in item) {
          return <HiveEventCard event={item} key={key} />
        }
      })}
    </div>
  )
}

export default GenericItemsSubGroup
