export interface AnimalEventsFilterSelections {
  searchQuery: string
  selectedAnimal: string
  type: string | undefined
}

export const SINGLE = "single" as const
export const MULTIPLE = "multiple" as const

export enum ANIMAL_EVENT_TYPES_ENUM {
  TreatmentEventType = "TreatmentEventType",
  MeasurementEventType = "MeasurementEventType",
  MovementEventType = "MovementEventType",
  ReproductiveEventType = "ReproductiveEventType",
  OtherEventType = "OtherEventType",
}
