import { ANIMAL_EVENT_TYPES_ENUM } from "@/features/events/animal-events/types/animal-events-constants.ts"
import { type AnimalEventType } from "@/features/events/animal-events/types/animal-events.ts"

export const getIconBackgroundColor = (eventType: string) => {
  switch (eventType) {
    case ANIMAL_EVENT_TYPES_ENUM.MeasurementEventType:
      return "var(--color-brown-500)"
    case ANIMAL_EVENT_TYPES_ENUM.MovementEventType:
      return "var(--color-yellow-500)"
    case ANIMAL_EVENT_TYPES_ENUM.OtherEventType:
      return "var(--color-neutral-500)"
    case ANIMAL_EVENT_TYPES_ENUM.ReproductiveEventType:
      return "var(--color-red-500)"
    case ANIMAL_EVENT_TYPES_ENUM.TreatmentEventType:
      return "var(--color-blue-500)"
    default:
      return "var(--color-brown-500)"
  }
}

export const findParentTypeOfEvent = (
  map: Map<string, AnimalEventType[]>,
  valueToFind: string,
) => {
  for (const [key, value] of map.entries()) {
    const value_ids = value.map((element) => element.id)

    if (value_ids.includes(valueToFind)) {
      return key
    }
  }
  return null
}
