import { createSlice, type PayloadAction } from "@reduxjs/toolkit"
import {
  type QueenInput,
  type QueenOrigins,
} from "@/features/bees/types/queens.ts"
import { logout } from "@/redux/slices/auth-slice.ts"

type QueenDraftSlice = {
  drafts: Record<string, QueenInput>
}

const initialState: QueenDraftSlice = {
  drafts: {},
}

export const queenInputInitialState: QueenInput = {
  name: "",
  year: undefined,
  hive: "",
  apiary: "",
  color: undefined,
  origin: "" as QueenOrigins,
  notes: [],
  tasks: [],
  description: "",
  picture: "",
}

const queenDraftSlice = createSlice({
  name: "queenDraft",
  initialState,
  reducers: {
    setQueenDraft: (state, { payload }) => {
      state.drafts[payload.id] = payload.draft
    },
    setQueenDraftHive: (state, { payload }) => {
      state.drafts[payload.id].hive = payload.hive
    },
    setDraftQueenName: (state, { payload }) => {
      state.drafts[payload.id].name = payload.name
    },
    setDraftQueenYear: (state, { payload }) => {
      state.drafts[payload.id].year = payload.year
    },
    setDraftQueenColor: (state, { payload }) => {
      state.drafts[payload.id].color = payload.color
    },
    setDraftQueenOrigin: (state, { payload }) => {
      state.drafts[payload.id].origin = payload.origin
    },
    setDraftQueenDescription: (state, { payload }) => {
      state.drafts[payload.id].description = payload.description
    },
    setDraftQueenPicture: (state, { payload }) => {
      state.drafts[payload.id].picture = payload.picture
    },
    resetQueenDraft: (
      state,
      action: PayloadAction<{ id: string; draft?: Partial<QueenInput> }>,
    ) => {
      state.drafts[action.payload.id] = {
        ...queenInputInitialState,
        ...action.payload.draft,
      }
    },
    deleteQueenDraft: (state, action: PayloadAction<string>) => {
      delete state.drafts[action.payload]
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout.fulfilled, () => initialState)
  },
})

export default queenDraftSlice.reducer

export const {
  setQueenDraft,
  setDraftQueenName,
  setDraftQueenYear,
  setDraftQueenColor,
  setDraftQueenOrigin,
  setDraftQueenDescription,
  setDraftQueenPicture,
  resetQueenDraft,
  deleteQueenDraft,
  setQueenDraftHive,
} = queenDraftSlice.actions
