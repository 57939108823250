import { apiSlice, TagType } from "@/features/api/apiSlice.ts"
import { type HiveEventType } from "@/features/events/hive-events/types/hive-event-type.ts"

const HIVE_EVENT_TYPES_BASE_URL = "/hive-event-types/"

export const hiveEventTypesApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllHiveEventTypes: builder.query<HiveEventType[], void>({
      query: () => ({
        url: HIVE_EVENT_TYPES_BASE_URL,
      }),
      providesTags: () => [{ type: TagType.EventTypes, id: "LIST" }],
    }),
    createHiveEventType: builder.mutation<
      HiveEventType,
      Partial<HiveEventType>
    >({
      query: (body) => ({
        url: HIVE_EVENT_TYPES_BASE_URL,
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: TagType.EventTypes, id: "LIST" }],
    }),
    updateHiveEventType: builder.mutation<
      HiveEventType,
      Partial<HiveEventType>
    >({
      query: (body) => ({
        url: `${HIVE_EVENT_TYPES_BASE_URL}${body.id}/`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: [{ type: TagType.EventTypes, id: "LIST" }],
    }),
    deleteHiveEventType: builder.mutation<void, string>({
      query: (id) => ({
        url: `${HIVE_EVENT_TYPES_BASE_URL}${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: TagType.EventTypes, id: "LIST" }],
    }),
  }),
})

export const {
  useGetAllHiveEventTypesQuery,
  useCreateHiveEventTypeMutation,
  useUpdateHiveEventTypeMutation,
  useDeleteHiveEventTypeMutation,
} = hiveEventTypesApi
