import type GeneralSvgProps from "@/utils/types/sgv/general-svg-props.ts"

const RoundedEditIcon: React.FC<GeneralSvgProps> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      className={props.className}
    >
      <rect width="20" height="20" rx="10" fill="#EEEEEE" />
      <path
        d="M11.1436 8.33333L11.6657 8.85556L6.622 13.8889H6.11096V13.3778L11.1436 8.33333ZM13.1433 5C13.0044 5 12.86 5.05556 12.7545 5.16111L11.738 6.17778L13.821 8.26111L14.8375 7.24444C15.0542 7.02778 15.0542 6.66667 14.8375 6.46111L13.5377 5.16111C13.4266 5.05 13.2877 5 13.1433 5ZM11.1436 6.77222L5 12.9167V15H7.08304L13.2266 8.85556L11.1436 6.77222Z"
        fill="#A3A3A3"
      />
    </svg>
  )
}

export default RoundedEditIcon
