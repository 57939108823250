import { format } from "date-fns"
import React, { useState } from "react"
import { type DateInterval } from "react-day-picker"
import { useTranslation } from "react-i18next"
import styles from "./generic-date-picker.module.css"
import DateIcon from "@/assets/icons/task/date-icon.tsx"
import GenericCalendar from "@/components/generic-date-picker/generic-calendar.tsx"
import OverflowMenuItem from "@/components/task-card/overflow-menu-item.tsx"
import RequiredIndicator from "@/features/farm/components/required-indicator/required-indicator.tsx"

interface DatePickerProps {
  value: string
  onChange: (date: string) => void
  isDisabled?: boolean
  dateInterval?: DateInterval
  placeholder?: string
  withFooter?: boolean
  isRequired?: boolean
}

const GenericDatePicker: React.FC<DatePickerProps> = ({
  value,
  onChange,
  isDisabled = false,
  dateInterval,
  placeholder = "Data",
  withFooter = true,
  isRequired,
}) => {
  const { t } = useTranslation()
  const [isOpened, setIsOpened] = useState<boolean>(false)
  const receivedDate = value ? new Date(value) : undefined

  const handleSelect = (date: Date | undefined) => {
    if (date) {
      onChange(format(new Date(date), "yyyy-MM-dd"))
    } else {
      onChange("")
    }
  }

  return (
    <OverflowMenuItem isDisabled={isDisabled}>
      <div
        className={styles.datePickerHeader}
        onClick={() => setIsOpened((prevState) => !prevState)}
      >
        <div className={styles.iconWrapper}>
          <DateIcon />
          <div>
            <p>{t(placeholder)}</p>
            {value && (
              <p className={styles.timeInfo}>{format(value, "dd.MM.yyyy")}</p>
            )}
          </div>
        </div>
        {isRequired && <RequiredIndicator />}
      </div>
      {isOpened && (
        <GenericCalendar
          receivedDate={receivedDate}
          setIsOpened={setIsOpened}
          onChange={handleSelect}
          dateInterval={dateInterval}
          withFooter={withFooter}
        />
      )}
    </OverflowMenuItem>
  )
}

export default GenericDatePicker
