import isEqual from "lodash.isequal"
import { useCallback, useLayoutEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import DeleteButton from "@/components/delete-button/delete-button.tsx"
import { LoadingAnimation } from "@/components/loading-animation/loading-animation.tsx"
import PageContentWrapperLarge from "@/components/page-content-wrappers/page-content-wrapper-large.tsx"
import EditorTopBar from "@/components/top-bars/editor-top-bar/editor-top-bar.tsx"
import {
  useDeleteHiveMutation,
  useUpdateHiveMutation,
} from "@/features/bees/api/hives-api.ts"
import { HiveEditor } from "@/features/bees/components/hive-editor/hive-editor.tsx"
import { useGetHiveDraft } from "@/features/bees/hooks/use-get-hive-draft.ts"
import { validateHive } from "@/features/bees/types/hives-schema.tsx"
import useModal from "@/features/modals/hooks/use-modal.ts"
import { MODALS } from "@/features/modals/types/modals.tsx"
import useNavigationFallback from "@/hooks/use-navigation-fallback.ts"
import { deleteHiveDraft } from "@/redux/slices/hive-draft-slice.ts"
import { APIARIES_SCREEN_PATH } from "@/utils/constants/routes.ts"
import { DELETE_ENTRY_TIMEOUT } from "@/utils/constants/time-constants.ts"
import { getObjectDifference } from "@/utils/helpers/object-utils.ts"

export const EditHiveView = () => {
  const { apiaryId, hiveId } = useParams()
  const { t } = useTranslation()
  const { openModal } = useModal()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [updateHiveMutation] = useUpdateHiveMutation()
  const [deleteHiveMutation] = useDeleteHiveMutation()
  const { handleBackNavigation } = useNavigationFallback()
  const { draftHive, data, isLoading } = useGetHiveDraft(hiveId)

  const title = draftHive?.code || t("loading")
  const hasChanges = !!draftHive && !!data && !isEqual(data, draftHive)

  const deleteDraft = useCallback(() => {
    setTimeout(() => {
      if (!hiveId) return
      dispatch(deleteHiveDraft(hiveId))
    }, DELETE_ENTRY_TIMEOUT)
  }, [hiveId, dispatch])

  const handleBackClick = () => {
    if (hasChanges) {
      openModal(MODALS.CONFIRM_NAVIGATION_MODAL, {
        title: t("unsavedChanges"),
        content: t("youWillLoseChanges"),
        onBackText: t("leave"),
        onActionText: t("stay"),
        onBackClick: () => {
          handleBackNavigation()
          deleteDraft()
        },
      })
    } else {
      handleBackNavigation()
      deleteDraft()
    }
  }

  const deleteHive = () => {
    if (hiveId && apiaryId) {
      toast
        .promise(deleteHiveMutation({ hiveId, apiaryId }).unwrap(), {
          pending: t("deletingHive"),
          success: t("hiveDeleted"),
          error: t("hiveDeleteError"),
        })
        .then(() => {
          navigate(`${APIARIES_SCREEN_PATH}/${apiaryId}`)
          deleteDraft()
        })
    }
  }

  const handleHiveDeletion = () => {
    openModal(MODALS.BASE_MODAL, {
      title: t("deleteHiveConfirmation"),
      content: t("hiveWillBeDeleted"),
      onActionClick: deleteHive,
    })
  }

  const handleHiveUpdate = async () => {
    if (!(await validateHive(draftHive))) {
      return
    }

    if (hiveId && apiaryId && data) {
      const diffData = getObjectDifference(data, draftHive)
      const promise = updateHiveMutation({
        apiaryId,
        hiveId,
        hive: diffData,
      }).unwrap()
      toast
        .promise(promise, {
          pending: t("hiveUpdating"),
          success: t("hiveUpdatedSuccessfully"),
          error: t("errorUpdatingHive"),
        })
        .then(() => {
          handleBackNavigation()
          deleteDraft()
        })
    }
  }

  useLayoutEffect(() => {
    if (hasChanges) {
      openModal(MODALS.CONFIRM_NAVIGATION_MODAL, {
        title: t("continueWhereYouLeftOff"),
        content: t("youHaveUnsavedChanges"),
        onBackText: t("discard"),
        onActionText: t("continue"),
        onBackClick: () => {
          deleteDraft()
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteDraft, openModal, t])

  return (
    <>
      <EditorTopBar
        text={title}
        isModified={hasChanges}
        onBackClick={handleBackClick}
        onSave={handleHiveUpdate}
      />
      {isLoading || !apiaryId || !hiveId || !draftHive ? (
        <LoadingAnimation />
      ) : (
        <PageContentWrapperLarge>
          <HiveEditor hiveId={hiveId} draftHive={draftHive} />
          <DeleteButton text={t("deleteHive")} onClick={handleHiveDeletion} />
        </PageContentWrapperLarge>
      )}
    </>
  )
}
