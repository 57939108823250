import { createSlice, type PayloadAction } from "@reduxjs/toolkit"
import { format } from "date-fns"
import { type HiveEvent } from "@/features/events/hive-events/types/hive-event.ts"

export type HiveEventDraft = Omit<
  HiveEvent,
  "created_at" | "updated_at" | "user" | "id"
>

type HiveEventsSlice = {
  draft: HiveEventDraft
}

export const initialHiveEventState: HiveEventDraft = {
  date: format(new Date(), "yyyy-MM-dd"),
  description: "",
  notes: "",
  type: "",
  hive: "",
  person_name: "",
}

const initialHiveEventsDraftState: HiveEventsSlice = {
  draft: initialHiveEventState,
}

const hiveEventsSlice = createSlice({
  name: "hiveEventsDraft",
  initialState: initialHiveEventsDraftState,
  reducers: {
    setHiveEventsDraft: (state, action) => {
      state.draft = action.payload
    },
    setDraftHiveEventType: (state, action) => {
      state.draft.type = action.payload
    },
    setDraftHiveEventDate: (state, action) => {
      state.draft.date = action.payload
    },
    setDraftHiveEventNotes: (state, action) => {
      state.draft.notes = action.payload
    },
    setDraftHiveEventHive: (state, action) => {
      state.draft.hive = action.payload
    },
    setDraftHiveEventDescription: (state, action) => {
      state.draft.description = action.payload
    },
    setDraftHiveEventPersonName: (state, action) => {
      state.draft.person_name = action.payload
    },
    setHiveDraftEventSubDataMedication: (
      state,
      action: PayloadAction<string | undefined>,
    ) => {
      state.draft = {
        ...state.draft,
        event_data: {
          ...state.draft.event_data,
          medication: action.payload,
        },
      }
    },
    setHiveDraftEventSubDataDosage: (state, action: PayloadAction<string>) => {
      state.draft = {
        ...state.draft,
        event_data: {
          ...state.draft.event_data,
          dosage: action.payload,
        },
      }
    },
    setHiveDraftEventSubDataMeasurementType: (
      state,
      action: PayloadAction<string | undefined>,
    ) => {
      state.draft = {
        ...state.draft,
        event_data: {
          ...state.draft.event_data,
          measurement_type: action.payload,
        },
      }
    },
    setHiveDraftEventSubDataMeasurementUnit: (
      state,
      action: PayloadAction<string | undefined>,
    ) => {
      state.draft = {
        ...state.draft,
        event_data: {
          ...state.draft.event_data,
          unit: action.payload,
        },
      }
    },
    setHiveDraftEventSubDataMeasurementValue: (
      state,
      action: PayloadAction<string | undefined>,
    ) => {
      state.draft = {
        ...state.draft,
        event_data: {
          ...state.draft.event_data,
          value: action.payload,
        },
      }
    },
    setHiveDraftEventSubDataToApiary: (
      state,
      action: PayloadAction<string | undefined>,
    ) => {
      state.draft = {
        ...state.draft,
        event_data: {
          ...state.draft.event_data,
          to_apiary: action.payload,
        },
      }
    },
    setHiveDraftEventSubDataFromPosition: (
      state,
      action: PayloadAction<string | undefined>,
    ) => {
      state.draft = {
        ...state.draft,
        event_data: {
          ...state.draft.event_data,
          from_position: action.payload,
        },
      }
    },
    setHiveDraftEventSubDataToPosition: (
      state,
      action: PayloadAction<string | undefined>,
    ) => {
      state.draft = {
        ...state.draft,
        event_data: {
          ...state.draft.event_data,
          to_position: action.payload,
        },
      }
    },
    setHiveDraftEventSubDataEquipmentType: (
      state,
      action: PayloadAction<string | undefined>,
    ) => {
      state.draft = {
        ...state.draft,
        event_data: {
          ...state.draft.event_data,
          equipment_type: action.payload,
        },
      }
    },
    setHiveDraftEventSubDataAction: (
      state,
      action: PayloadAction<string | undefined>,
    ) => {
      state.draft = {
        ...state.draft,
        event_data: {
          ...state.draft.event_data,
          action: action.payload,
        },
      }
    },
    setHiveDraftEventSubDataQuantity: (
      state,
      action: PayloadAction<string | undefined>,
    ) => {
      state.draft = {
        ...state.draft,
        event_data: {
          ...state.draft.event_data,
          quantity: action.payload,
        },
      }
    },
    setHiveDraftEventSubDataPosition: (
      state,
      action: PayloadAction<string | undefined>,
    ) => {
      state.draft = {
        ...state.draft,
        event_data: {
          ...state.draft.event_data,
          position: action.payload,
        },
      }
    },
    resetHiveEventSubData: (state) => {
      state.draft = {
        ...state.draft,
        event_data: {},
      }
    },
    resetHiveEventDraft: (state, action: PayloadAction<Partial<HiveEvent>>) => {
      state.draft = { ...initialHiveEventState, ...action.payload }
    },
  },
})

export const {
  setHiveEventsDraft,
  setDraftHiveEventType,
  setDraftHiveEventDate,
  setDraftHiveEventPersonName,
  setDraftHiveEventNotes,
  setDraftHiveEventHive,
  setDraftHiveEventDescription,
  resetHiveEventDraft,
  setHiveDraftEventSubDataMedication,
  setHiveDraftEventSubDataDosage,
  setHiveDraftEventSubDataMeasurementType,
  setHiveDraftEventSubDataMeasurementUnit,
  setHiveDraftEventSubDataMeasurementValue,
  setHiveDraftEventSubDataToApiary,
  setHiveDraftEventSubDataFromPosition,
  setHiveDraftEventSubDataToPosition,
  setHiveDraftEventSubDataEquipmentType,
  setHiveDraftEventSubDataAction,
  setHiveDraftEventSubDataQuantity,
  setHiveDraftEventSubDataPosition,
  resetHiveEventSubData,
} = hiveEventsSlice.actions

export default hiveEventsSlice.reducer
