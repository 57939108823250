import { format } from "date-fns"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import styles from "@/features/events/common/components/events-table/events-table.module.css"
import { type BaseEvent } from "@/features/events/common/types/common-events-types.ts"
import { useGetAllHiveEventTypesQuery } from "@/features/events/hive-events/api/hive-event-types-api.ts"
import { type HiveEventType } from "@/features/events/hive-events/types/hive-event-type.ts"
import { type HiveEvent } from "@/features/events/hive-events/types/hive-event.ts"
import { HIVE_EVENTS_ROUTE } from "@/utils/constants/routes.ts"

interface Props<T extends BaseEvent> {
  event: T
  eventTypes: HiveEventType[]
  routePath: string
}

export const HiveEventTableRow = <T extends BaseEvent>({
  event,
  eventTypes,
  routePath,
}: Props<T>) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const eventTypeName =
    eventTypes?.find((type) => type.id === event.type)?.name ?? ""
  const navigateToEvent = () => {
    navigate(`${routePath}/${event.id}`)
  }
  return (
    <tr className={styles.tableRow} onClick={navigateToEvent}>
      {eventTypeName && (
        <>
          <td className={styles.tableCell}>{t(eventTypeName)}</td>
          <td className={styles.dateCell}>
            {format(new Date(event.date), "dd.MM.yyyy")}
          </td>
        </>
      )}
    </tr>
  )
}

export const HiveEventsTableRow = ({ event }: { event: HiveEvent }) => {
  const { data: eventTypes } = useGetAllHiveEventTypesQuery()

  return (
    <HiveEventTableRow
      event={event}
      eventTypes={eventTypes ?? []}
      routePath={HIVE_EVENTS_ROUTE}
    />
  )
}
