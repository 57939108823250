import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { ApiariesIcon } from "@/assets/icons/bees/apiaries-icon.tsx"
import NrBeesBeginningIcon from "@/assets/icons/bees/nr-bees-beginning-icon.tsx"
import GenericPicture from "@/components/generic-picture/generic-picture.tsx"
import { useGetApiaryByIdQuery } from "@/features/bees/api/apiaries-api.ts"
import { type HiveResponse } from "@/features/bees/types/hives.ts"
import { getImageForHive } from "@/features/bees/utils/apiaries-utils.ts"
import {
  APIARIES_SCREEN_PATH,
  ALL_HIVES_PATH,
} from "@/utils/constants/routes.ts"

interface Props {
  hive: HiveResponse
}

export const HiveCard: React.FC<Props> = ({ hive }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { data: apiary } = useGetApiaryByIdQuery(hive.apiary)
  const navigateToHive = () => {
    navigate(
      `${APIARIES_SCREEN_PATH}/${hive.apiary}${ALL_HIVES_PATH}/${hive.id}/`,
    )
  }

  return (
    <div
      onClick={navigateToHive}
      className={
        "grid min-h-[90px] w-full grid-cols-[60px_auto] gap-2 rounded-[var(--fp-border-radius)] border border-amber-200 p-4"
      }
    >
      <div
        className={
          "bg-hive relative h-[60px] w-[60px] overflow-hidden rounded-[4px] border-2 border-amber-200"
        }
      >
        <GenericPicture
          style={{
            position: "absolute",
            bottom: 0,
            right: 0,
          }}
          size={50}
          src={getImageForHive(hive)}
          alt={"hiveImage"}
        />
      </div>
      <div className={"flex flex-col justify-between"}>
        <div className={"flex items-center gap-2"}>
          <div className={"text-[16px] font-semibold text-neutral-700"}>
            {hive.code}
          </div>
          {Object.hasOwn(hive, "hasChanges") && (
            <i className={"italic text-gray-500"}>
              {t("unsavedChangesIndicator")}
            </i>
          )}
        </div>
        <div className={"flex gap-2"}>
          {apiary && (
            <div className={"flex gap-2 rounded-[8px] bg-brown-50 px-2 py-1"}>
              <ApiariesIcon className={"scale-75 text-brown-500"} />
              {apiary.name}
            </div>
          )}
          <div className={"flex gap-2 rounded-[8px] bg-brown-50 px-2 py-1"}>
            <NrBeesBeginningIcon className={"text-brown-500"} />
            {hive.bee_count} {t("bees")}
          </div>
        </div>
      </div>
    </div>
  )
}
