import { type CommonBeesEventBase } from "@/features/events/common/types/common-bees-event-base.ts"

export enum FloraTypes {
  ACACIA = "Acacia",
  LINDEN = "Linden",
  SUNFLOWER = "Sunflower",
  RAPESEED = "Rapeseed",
  POLY_FLORAL = "Poly Floral",
  OTHER = "Other",
}

export const floraTypesOptions = Object.values(FloraTypes).map((type) => ({
  value: type,
  label: type,
}))

export interface ApiaryEvent extends CommonBeesEventBase {
  apiary: string
  type: string
  event_data?: ApiaryMovementEvent
}

export interface ApiaryMovementEvent {
  from_location?: string
  to_location?: string
  is_permanent?: boolean
  departure_date?: string
  flora_type?: FloraTypes
}
