import { type ReactNode, useState } from "react"
import { useTranslation } from "react-i18next"
import ArrowDown from "@/assets/icons/misc/arrow-down.tsx"
import { cn } from "@/utils/styles.ts"

interface Props {
  text: string
  value: string
  children: ReactNode
  iconClassName?: string
  enableExpand?: boolean
}

const HiveDetailsRow: React.FC<Props> = ({
  text,
  value,
  children,
  iconClassName,
  enableExpand = false,
}) => {
  const { t } = useTranslation()
  const onExpandClick = () => {
    setIsExpanded(!isExpanded)
  }
  const [isExpanded, setIsExpanded] = useState(false)
  const iconClasses = cn(
    "grid h-[30px] w-[30px] place-content-center rounded-[4px] bg-brown-500 text-white",
    iconClassName,
  )

  const valueClasses = cn(
    isExpanded
      ? "h-fit w-full flex-wrap whitespace-normal break-words pl-[38px]"
      : "truncate",
  )

  return (
    <div
      onClick={onExpandClick}
      className={"flex w-full justify-between gap-2"}
    >
      <div
        className={cn(
          "flex items-center gap-2 truncate",
          isExpanded && "w-full flex-col items-start",
        )}
      >
        <div className={"grid grid-cols-[30px_auto] items-center gap-2"}>
          <div className={iconClasses}>{children}</div>
          <p className={"flex text-gray-500"}>{text}</p>
        </div>
        {value && <p className={valueClasses}>{t(value)}</p>}
      </div>
      {enableExpand && (
        <div className={"pt-2"}>
          <ArrowDown isDown={!isExpanded} />
        </div>
      )}
    </div>
  )
}

export default HiveDetailsRow
