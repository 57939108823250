import React from "react"
import { HiveCard } from "@/features/bees/components/apiary-widget/hive-card.tsx"
import { type HiveSummary } from "@/features/bees/types/hives.ts"

interface Props {
  hives: HiveSummary[]
  apiaryId: string
}

export const ScrollableHiveList: React.FC<Props> = ({ hives, apiaryId }) => {
  return (
    <div className={"mx-[-16px] overflow-auto scrollbar-hide"}>
      <div className="flex w-fit flex-row flex-nowrap items-center gap-[10px] px-4">
        {hives.map((hive, index) => (
          <HiveCard hive={hive} key={index} apiaryId={apiaryId} />
        ))}
      </div>
    </div>
  )
}
