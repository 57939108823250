import { useEffect } from "react"

/**
 * This hook prefetches images from a link, it uses Image class to avoid CORS
 * and to ensure the caching is taking place
 *
 * This must be used in conjunction with request.destination === "image", as caching verification
 * @param imageUrls
 */
const usePrefetchImages = (imageUrls: string[] | undefined) => {
  useEffect(() => {
    if (!imageUrls) return
    if (!imageUrls.length) return
    if (imageUrls.length) {
      imageUrls.forEach((url) => {
        const img = new Image()
        img.src = url
      })
    }
  }, [imageUrls])
}

export default usePrefetchImages
