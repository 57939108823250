import type GeneralSvgProps from "@/utils/types/sgv/general-svg-props.ts"

export const HivePositionIcon: React.FC<GeneralSvgProps> = (props) => {
  return (
    <svg
      {...props}
      viewBox="-4.41 -4.41 29.82 29.82"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      fill="#000000"
    >
      <g id="SVGRepo_bgCarrier" stroke-width="0">
        <rect
          x="-4.41"
          y="-4.41"
          width="29.82"
          height="29.82"
          rx="5.665800000000001"
          fill="#aaec7e"
          strokeWidth="0"
        ></rect>
      </g>
      <g
        id="SVGRepo_tracerCarrier"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        <g
          id="Page-1"
          stroke-width="0.00021000000000000004"
          fill="none"
          fill-rule="evenodd"
        >
          <g
            id="Dribbble-Light-Preview"
            transform="translate(-299.000000, -160.000000)"
            fill="#000000"
          >
            <g id="icons" transform="translate(56.000000, 160.000000)">
              <path
                d="M245.1,18 L261.9,18 L261.9,2 L245.1,2 L245.1,18 Z M243,20 L264,20 L264,0 L243,0 L243,20 Z M247.2,12 L259.8,12 L259.8,10 L247.2,10 L247.2,12 Z M247.2,16 L259.8,16 L259.8,14 L247.2,14 L247.2,16 Z"
                id="shelf_double_down-[#1534]"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
