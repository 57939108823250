import React from "react"
import { LocationDrawer } from "@/features/events/common/components/location-drawer/location-drawer.tsx"
import { type Option } from "@/utils/types/misc-types.ts"

interface Props {
  firstPlaceholder: string
  secondPlaceholder: string
  firstValue: string | undefined
  secondValue: string | undefined
  onChangeFirstValue: (value: string) => void
  onChangeSecondValue: (value: string) => void
  options: Option[]
}

export const EventLocationTupleDrawer: React.FC<Props> = ({
  firstPlaceholder,
  secondPlaceholder,
  firstValue,
  secondValue,
  onChangeFirstValue,
  onChangeSecondValue,
  options,
}) => {
  const filteredLocationList = options.filter(
    (option) => option.value !== secondValue && option.value !== firstValue,
  )

  return (
    <>
      <LocationDrawer
        value={firstValue}
        onChangeValue={onChangeFirstValue}
        options={filteredLocationList}
        placeholder={firstPlaceholder}
      />
      <LocationDrawer
        value={secondValue}
        onChangeValue={onChangeSecondValue}
        options={filteredLocationList}
        placeholder={secondPlaceholder}
      />
    </>
  )
}
