import React from "react"
import { type DateRange } from "react-day-picker"
import { useTranslation } from "react-i18next"
import { FilterScroll } from "@/components/filter-scroll/filter-scroll.tsx"
import { SelectPill } from "@/components/select-pill/select-pill.tsx"
import { SelectionRowDrawer } from "@/components/selection-row-drawer/selection-row-drawer.tsx"
import { DrawerTrigger } from "@/components/shadcn/drawer.tsx"
import { useGetApiariesQuery } from "@/features/bees/api/apiaries-api.ts"
import { HIVES_FILTER_ENUM } from "@/features/bees/utils/hive-filter.ts"
import useMapEventTypesToParents from "@/features/events/animal-events/hooks/use-map-event-types-to-parents.ts"
import { type AnimalEventType } from "@/features/events/animal-events/types/animal-events.ts"
import { useGetAllApiaryEventTypesQuery } from "@/features/events/apiary-events/api/apiary-event-types-api.ts"
import { CommonEventsDrawer } from "@/features/events/common/components/common-events-drawer/common-events-drawer.tsx"
import { DateFilterDrawer } from "@/features/events/common/components/date-filter-drawer/date-filter-drawer.tsx"

interface ApiaryEventsFilterProps {
  selectedApiary: string
  onSelectedApiaryChange: (apiary: string) => void
  selectedTimeFrame: DateRange | undefined
  onSelectedTimeFrameChange: (dateRange: DateRange | undefined) => void
  selectedEventType: string
  onSelectedEventTypeChange: (eventType: string) => void
}

export const ApiaryEventsFilter: React.FC<ApiaryEventsFilterProps> = ({
  selectedApiary,
  onSelectedApiaryChange,
  selectedEventType,
  onSelectedEventTypeChange,
  onSelectedTimeFrameChange,
  selectedTimeFrame,
}) => {
  const { data: apiaries } = useGetApiariesQuery()
  const { data: apiariesEventTypes } = useGetAllApiaryEventTypesQuery()
  const { t } = useTranslation()
  const eventTypesMap = apiariesEventTypes?.reduce<
    Record<string, AnimalEventType>
  >((acc, eventType) => {
    return { ...acc, [eventType.id]: eventType as AnimalEventType }
  }, {})
  const parentEventTypes = useMapEventTypesToParents(eventTypesMap)
  const apiariesOptions = [
    {
      value: HIVES_FILTER_ENUM.ALL_APIARIES,
      label: HIVES_FILTER_ENUM.ALL_APIARIES,
    },
    ...(apiaries
      ? apiaries.map((apiary) => ({
          value: apiary.id,
          label: apiary.name,
        }))
      : []),
  ]

  const handleApiaryChange = (apiaryId: string) => {
    onSelectedApiaryChange(apiaryId)
  }

  const handleEventTypeChange = (eventType: string | undefined) => {
    onSelectedEventTypeChange(eventType || "")
  }

  const selectedApiaryOption = apiariesOptions.find(
    (option) => option.value === selectedApiary,
  )

  return (
    <FilterScroll>
      <SelectionRowDrawer
        multiple={false}
        renderTrigger={
          <DrawerTrigger>
            <SelectPill
              isActive={selectedApiary !== HIVES_FILTER_ENUM.ALL_APIARIES}
            >
              <div>
                {selectedApiaryOption
                  ? t(selectedApiaryOption?.label)
                  : t(apiariesOptions[0].label)}
              </div>
            </SelectPill>
          </DrawerTrigger>
        }
        title={t("selectApiary")}
        options={apiariesOptions}
        selectedValues={selectedApiary}
        onSelectionChange={handleApiaryChange}
      />
      <CommonEventsDrawer
        eventTypesMap={parentEventTypes}
        fallbackText={t("selectEventType")}
        onEventSelect={handleEventTypeChange}
        selectedValue={selectedEventType}
      />
      <DateFilterDrawer
        selectedTimeFrame={selectedTimeFrame}
        onSelectedTimeFrameChange={onSelectedTimeFrameChange}
      />
    </FilterScroll>
  )
}
