import isEmpty from "lodash/isEmpty"
import isNil from "lodash/isNil"
import omitBy from "lodash/omitBy"
import { apiSlice, TagType } from "@/features/api/apiSlice.ts"
import {
  type Hive,
  type HiveInput,
  type HiveResponse,
} from "@/features/bees/types/hives.ts"

const HIVES_API_URL = "/hives/"

export const hivesApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createHive: builder.mutation<HiveResponse, HiveInput>({
      query: (hive) => ({
        url: `${HIVES_API_URL}`,
        method: "POST",
        body: omitBy(hive, (value) => isNil(value) || isEmpty(value)),
      }),
      invalidatesTags: (_queryCache, _error, hive) => [
        { type: TagType.Hives, id: "LIST" },
        { type: TagType.Apiaries, id: "LIST" },
        { type: TagType.Apiaries, id: hive.apiary },
      ],
    }),
    getAllHives: builder.query<HiveResponse[], void>({
      query: () => `${HIVES_API_URL}`,
      providesTags: [{ type: TagType.Hives, id: "LIST" }],
    }),
    getHiveById: builder.query<HiveResponse, string>({
      query: (hiveId) => `${HIVES_API_URL}${hiveId}/`,
      providesTags: (_result, _error, hiveId) => [
        { type: TagType.Hives, id: hiveId },
      ],
    }),
    deleteHive: builder.mutation<void, { apiaryId: string; hiveId: string }>({
      query: ({ hiveId }) => ({
        url: `${HIVES_API_URL}${hiveId}/`,
        method: "DELETE",
      }),
      invalidatesTags: (_result, _error, { apiaryId, hiveId }) => [
        { type: TagType.Hives, id: "LIST" },
        { type: TagType.Hives, id: hiveId },
        { type: TagType.Apiaries, id: "LIST" },
        { type: TagType.Apiaries, id: apiaryId },
      ],
    }),
    updateHive: builder.mutation<
      Hive,
      { apiaryId: string; hiveId: string; hive: Partial<HiveInput> }
    >({
      query: ({ hive, hiveId }) => ({
        url: `${HIVES_API_URL}${hiveId}/`,
        method: "PATCH",
        body: hive,
      }),
      invalidatesTags: (_result, _error, { apiaryId, hiveId }) => [
        { type: TagType.Hives, id: "LIST" },
        { type: TagType.Hives, id: hiveId },
        { type: TagType.Apiaries, id: "LIST" },
        { type: TagType.Apiaries, id: apiaryId },
      ],
    }),
  }),
})

export const {
  useCreateHiveMutation,
  useGetHiveByIdQuery,
  useGetAllHivesQuery,
  useDeleteHiveMutation,
  useUpdateHiveMutation,
} = hivesApi
