import { useTranslation } from "react-i18next"
import Skeleton from "@/components/skeleton/skeleton.tsx"

export const ApiaryDetailsSkeleton = () => {
  const { t } = useTranslation()

  return (
    <div
      className={"widget-border box-border flex flex-col gap-5 bg-white p-4"}
    >
      <div className={"text-[20px] font-semibold text-neutral-700"}>
        {t("apiaryDetails")}
      </div>
      <div className={"flex flex-col gap-2"}>
        <Skeleton className={"h-[30px] rounded-[8px]"} />
        <Skeleton className={"h-[30px] rounded-[8px]"} />
        <Skeleton className={"h-[30px] rounded-[8px]"} />
        <Skeleton className={"h-[30px] rounded-[8px]"} />
      </div>
    </div>
  )
}
