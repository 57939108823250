import React from "react"
import { useTranslation } from "react-i18next"
import styles from "./no-results-found.module.css"
import ApiaryNotFoundSvg from "@/assets/error-handling/no-apiary-found.svg"
import HiveNotFoundSvg from "@/assets/error-handling/no-hive-found.svg"
import LocationNotFoundPng from "@/assets/error-handling/no-location.png"
import ObjectNotFoundSvg from "@/assets/error-handling/object-not-found.svg"
import { cn } from "@/utils/styles.ts"

interface NoResultsFoundProps {
  message?: string
  children?: React.ReactNode
  className?: string
  imageClassName?: string
  image?: "generic" | "hive" | "apiary" | "location"
}

const NoResultsFound: React.FC<NoResultsFoundProps> = ({
  message,
  children,
  className,
  imageClassName,
  image = "generic",
}) => {
  const { t } = useTranslation()

  const getImage = () => {
    switch (image) {
      case "hive":
        return HiveNotFoundSvg
      case "location":
        return LocationNotFoundPng
      case "apiary":
        return ApiaryNotFoundSvg
      case "generic":
      default:
        return ObjectNotFoundSvg
    }
  }

  return (
    <div className={cn(className, styles.error)}>
      <img
        src={getImage()}
        alt="Not Found"
        className={cn(imageClassName, styles.notFoundImg)}
      />
      {message && <p className={styles.errorMessage}>{t(message)}</p>}
      {children}
    </div>
  )
}

export default NoResultsFound
