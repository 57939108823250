import isEqual from "lodash.isequal"
import { useLayoutEffect } from "react"
import { useDispatch } from "react-redux"
import { useSearchParams } from "react-router-dom"
import { APIARY_QUERY_PARAMS } from "@/features/events/common/types/apiary-querry-params.ts"
import { useAppSelector } from "@/redux/hooks.ts"
import {
  initialHiveEventState,
  resetHiveEventDraft,
  setDraftHiveEventHive,
} from "@/redux/slices/hive-events-draft-slice.ts"

export const useInitializeHiveEventDraft = () => {
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()
  const hiveId = searchParams.get(APIARY_QUERY_PARAMS.HIVE_ID)
  const hiveEventDraft = useAppSelector(
    (state) => state.hiveEventsDraftSlice.draft,
  )

  const baselineHiveEventDraft = {
    ...initialHiveEventState,
    hive: hiveId ? hiveId : hiveEventDraft.hive,
  }

  const hasChanges =
    hiveEventDraft && !isEqual(baselineHiveEventDraft, hiveEventDraft)

  useLayoutEffect(() => {
    if (hiveId) {
      dispatch(setDraftHiveEventHive(hiveId))
    } else dispatch(resetHiveEventDraft({}))
  }, [dispatch, hiveId])

  return { hiveEventDraft, hasChanges }
}
