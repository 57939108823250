import { createSlice, type PayloadAction } from "@reduxjs/toolkit"
import { format } from "date-fns"
import {
  type ApiaryEvent,
  type FloraTypes,
} from "@/features/events/apiary-events/types/apiary-event.ts"

export type ApiaryEventDraft = Omit<
  ApiaryEvent,
  "id" | "created_at" | "updated_at" | "user"
>

type ApiaryEventsSlice = {
  draft: ApiaryEventDraft
}

export const initialApiaryEventState: ApiaryEventDraft = {
  apiary: "",
  date: format(new Date(), "yyyy-MM-dd"),
  person_name: "",
  description: "",
  notes: "",
  type: "",
}

const initialApiaryEventsDraftState: ApiaryEventsSlice = {
  draft: initialApiaryEventState,
}

const apiaryEventsSlice = createSlice({
  name: "apiaryEventsDraft",
  initialState: initialApiaryEventsDraftState,
  reducers: {
    setApiaryEventsDraft: (state, action: PayloadAction<ApiaryEventDraft>) => {
      state.draft = action.payload
    },
    setDraftApiaryEventType: (state, action: PayloadAction<string>) => {
      state.draft.type = action.payload
    },
    setDraftApiaryEventDate: (state, action: PayloadAction<string>) => {
      state.draft.date = action.payload
    },
    setDraftApiaryEventNotes: (state, action: PayloadAction<string>) => {
      state.draft.notes = action.payload
    },
    setDraftApiaryEventApiary: (state, action: PayloadAction<string>) => {
      state.draft.apiary = action.payload
    },
    setDraftApiaryEventDescription: (state, action: PayloadAction<string>) => {
      state.draft.description = action.payload
    },
    setDraftApiaryPersonName: (state, action: PayloadAction<string>) => {
      state.draft.person_name = action.payload
    },
    setDraftEventSubDataFromLocation: (
      state,
      action: PayloadAction<string>,
    ) => {
      state.draft = {
        ...state.draft,
        event_data: {
          ...state.draft.event_data,
          from_location: action.payload,
        },
      }
    },
    setDraftEventSubDataToLocation: (state, action: PayloadAction<string>) => {
      state.draft = {
        ...state.draft,
        event_data: {
          ...state.draft.event_data,
          to_location: action.payload,
        },
      }
    },
    setDraftEventSubDataIsPermanent: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.draft = {
        ...state.draft,
        event_data: {
          ...state.draft.event_data,
          is_permanent: action.payload,
        },
      }
    },
    setDraftEventSubDataDepartureLocation: (
      state,
      action: PayloadAction<string>,
    ) => {
      state.draft = {
        ...state.draft,
        event_data: {
          ...state.draft.event_data,
          departure_date: action.payload,
        },
      }
    },
    setDraftEventSubDataFloraType: (
      state,
      action: PayloadAction<FloraTypes>,
    ) => {
      state.draft = {
        ...state.draft,
        event_data: {
          ...state.draft.event_data,
          flora_type: action.payload,
        },
      }
    },
    resetApiaryEventDraft: (
      state,
      action: PayloadAction<Partial<ApiaryEvent>>,
    ) => {
      state.draft = { ...initialApiaryEventState, ...action.payload }
    },
  },
})

export default apiaryEventsSlice.reducer

export const {
  setApiaryEventsDraft,
  setDraftApiaryEventType,
  setDraftApiaryEventDate,
  setDraftApiaryEventNotes,
  setDraftApiaryEventApiary,
  setDraftApiaryEventDescription,
  setDraftApiaryPersonName,
  resetApiaryEventDraft,
  setDraftEventSubDataFromLocation,
  setDraftEventSubDataToLocation,
  setDraftEventSubDataIsPermanent,
  setDraftEventSubDataDepartureLocation,
  setDraftEventSubDataFloraType,
} = apiaryEventsSlice.actions
