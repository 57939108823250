import { QueenColors } from "@/features/bees/types/queens.ts"

export const queenColorBackground = (queenColor: string) => {
  switch (queenColor) {
    case QueenColors.Blue:
      return "bg-blue-500"
    case QueenColors.White:
      return "bg-[var(--grey)]"
    case QueenColors.Yellow:
      return "bg-yellow-300"
    case QueenColors.Red:
      return "bg-red-500"
    case QueenColors.Green:
      return "bg-green-500"
    default:
      return "bg-brown-500"
  }
}
