import { useCallback, useMemo } from "react"
import { useSearchParams } from "react-router-dom"
import { HIVES_FILTER_ENUM } from "@/features/bees/utils/hive-filter"
import { useGetAllHiveEventsQuery } from "@/features/events/hive-events/api/hive-events-api.ts"

interface FilterState {
  eventType: string
  apiary?: string
  hiveId?: string
  dateFrom?: string
  dateTo?: string
}

export enum APIARY_EVENTS_FILTER_ENUM {
  EVENT_TYPE = "event_type",
  APIARY_ID = "apiary_id",
  HIVE_ID = "hive_id",
  DATE_FROM = "date_from",
  DATE_TO = "date_to",
}

export const useFilterHiveEvents = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const currentFilters = useMemo(
    () => ({
      eventType: searchParams.get(APIARY_EVENTS_FILTER_ENUM.EVENT_TYPE) || "",
      apiaryId:
        searchParams.get(APIARY_EVENTS_FILTER_ENUM.APIARY_ID) ||
        HIVES_FILTER_ENUM.ALL_APIARIES,
      hiveId:
        searchParams.get(APIARY_EVENTS_FILTER_ENUM.HIVE_ID) ||
        HIVES_FILTER_ENUM.ALL_HIVES,
      dateFrom:
        searchParams.get(APIARY_EVENTS_FILTER_ENUM.DATE_FROM) || undefined,
      dateTo: searchParams.get(APIARY_EVENTS_FILTER_ENUM.DATE_TO) || undefined,
    }),
    [searchParams],
  )

  const { data, isLoading } = useGetAllHiveEventsQuery({
    event_type: currentFilters.eventType || undefined,
    apiary_id:
      currentFilters.apiaryId === HIVES_FILTER_ENUM.ALL_APIARIES
        ? undefined
        : currentFilters.apiaryId,
    hive_id:
      currentFilters.hiveId === HIVES_FILTER_ENUM.ALL_HIVES
        ? undefined
        : currentFilters.hiveId,
    date_from: currentFilters.dateFrom,
    date_to: currentFilters.dateTo,
  })

  const updateFilters = useCallback(
    (filters: Partial<FilterState>) => {
      const newParams = new URLSearchParams(searchParams)

      if (filters.eventType) {
        newParams.set(APIARY_EVENTS_FILTER_ENUM.EVENT_TYPE, filters.eventType)
      } else {
        newParams.delete(APIARY_EVENTS_FILTER_ENUM.EVENT_TYPE)
      }

      if (filters.apiary) {
        newParams.set(APIARY_EVENTS_FILTER_ENUM.APIARY_ID, filters.apiary)
      } else if (filters.apiary === HIVES_FILTER_ENUM.ALL_APIARIES) {
        newParams.delete(APIARY_EVENTS_FILTER_ENUM.APIARY_ID)
      }

      if (filters.hiveId) {
        newParams.set(APIARY_EVENTS_FILTER_ENUM.APIARY_ID, filters.hiveId)
      } else if (filters.apiary === HIVES_FILTER_ENUM.ALL_HIVES) {
        newParams.delete(APIARY_EVENTS_FILTER_ENUM.HIVE_ID)
      }

      if (filters.dateFrom) {
        newParams.set(APIARY_EVENTS_FILTER_ENUM.DATE_FROM, filters.dateFrom)
      } else {
        newParams.delete(APIARY_EVENTS_FILTER_ENUM.DATE_FROM)
      }

      if (filters.dateTo) {
        newParams.set(APIARY_EVENTS_FILTER_ENUM.DATE_TO, filters.dateTo)
      } else {
        newParams.delete(APIARY_EVENTS_FILTER_ENUM.DATE_TO)
      }

      setSearchParams(newParams, { replace: true })
    },
    [searchParams, setSearchParams],
  )

  return {
    filters: currentFilters,
    updateFilters,
    events: data,
    isLoading,
  }
}
