import { useCallback, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { LoadingAnimation } from "@/components/loading-animation/loading-animation.tsx"
import PageContentWrapperLarge from "@/components/page-content-wrappers/page-content-wrapper-large.tsx"
import EditorTopBar from "@/components/top-bars/editor-top-bar/editor-top-bar.tsx"
import { useCreateHiveMutation } from "@/features/bees/api/hives-api.ts"
import { HiveEditor } from "@/features/bees/components/hive-editor/hive-editor.tsx"
import { useInitializeHiveDraft } from "@/features/bees/hooks/use-initialize-hive-draft.ts"
import { validateHive } from "@/features/bees/types/hives-schema.tsx"
import useModal from "@/features/modals/hooks/use-modal.ts"
import { MODALS } from "@/features/modals/types/modals.tsx"
import useNavigationFallback from "@/hooks/use-navigation-fallback.ts"
import { deleteHiveDraft } from "@/redux/slices/hive-draft-slice.ts"
import {
  ALL_HIVES_PATH,
  APIARIES_SCREEN_PATH,
} from "@/utils/constants/routes.ts"
import { DELETE_ENTRY_TIMEOUT } from "@/utils/constants/time-constants.ts"
import { NEW_ENTITY } from "@/utils/types/misc-types.ts"

const NewHiveView = () => {
  const { t } = useTranslation()
  const { hiveDraft, hasChanges } = useInitializeHiveDraft()
  const [createHiveMutation] = useCreateHiveMutation()
  const { openModal } = useModal()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { handleBackNavigation } = useNavigationFallback()

  const deleteDraft = useCallback(() => {
    setTimeout(() => {
      dispatch(deleteHiveDraft(NEW_ENTITY))
    }, DELETE_ENTRY_TIMEOUT)
  }, [dispatch])

  const handleHiveCreation = async () => {
    if (!(await validateHive(hiveDraft))) {
      return
    }
    const promise = createHiveMutation(hiveDraft).unwrap()
    await toast
      .promise(promise, {
        pending: t("creatingHive"),
        success: t("hiveCreatedSuccessfully"),
        error: t("errorCreatingHive"),
      })
      .then((response) => {
        setTimeout(() => {
          navigate(
            `${APIARIES_SCREEN_PATH}/${response.apiary}${ALL_HIVES_PATH}/${response.id}/`,
            {
              state: { previousPath: location.pathname },
            },
          )
        }, 100)
        deleteDraft()
        openModal(MODALS.BASE_MODAL, {
          title: t("hiveWasSuccessfullyAdded"),
          content: t("queenStep"),
          onActionClick: () =>
            navigate(
              `${APIARIES_SCREEN_PATH}/${response.apiary}${ALL_HIVES_PATH}/${response.id}/queen/new`,
            ),
        })
      })
  }

  useEffect(() => {
    if (hasChanges) {
      openModal(MODALS.CONFIRM_NAVIGATION_MODAL, {
        title: t("continueWhereYouLeftOff"),
        content: t("youHaveUnsavedChanges"),
        onBackText: t("discard"),
        onActionText: t("continue"),
        onBackClick: () => {
          deleteDraft()
        },
      })
    }
    // Only run this effect on the first render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteDraft, openModal, t])

  const handleBackClick = () => {
    if (hasChanges) {
      openModal(MODALS.CONFIRM_NAVIGATION_MODAL, {
        title: t("unsavedChanges"),
        content: t("youWillLoseChanges"),
        onBackText: t("leave"),
        onActionText: t("stay"),
        onBackClick: () => {
          handleBackNavigation()
          deleteDraft()
        },
      })
    } else {
      handleBackNavigation()
      deleteDraft()
    }
  }

  return (
    <>
      <EditorTopBar
        onBackClick={handleBackClick}
        text={t("addHive")}
        isModified={hasChanges}
        onSave={handleHiveCreation}
      />
      <PageContentWrapperLarge>
        {!hiveDraft ? (
          <LoadingAnimation />
        ) : (
          <HiveEditor draftHive={hiveDraft} hiveId={NEW_ENTITY} />
        )}
      </PageContentWrapperLarge>
    </>
  )
}

export default NewHiveView
